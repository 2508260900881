import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";
import icons from "../icons";
import actions from "../actions";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import ConfigContext from "../contexts/AppContext";
import { useHistory } from "react-router-dom";
import uuid from "react-uuid";
import styled, { ThemeContext } from "styled-components";
import AppContext from "../contexts/AppContext";
import LeftBarContext from "../contexts/LeftBarContext";
import WithLoader from "./WithLoader";
import { createBrowserHistory } from "history";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import { appendQueryToUri } from "../util/generalUtil";

const Badge = styled.div`
  width: 18px;
  height: 18px;
  background: ${({ theme, active }) =>
    active ? theme?.colors.primary : theme?.colors.accent};
  font-size: 0.7em;
  color: ${({ theme, active }) => (active ? "#ffffff" : theme?.colors.primary)};
`;

const MenuText = styled.div`
  color: ${({ theme, active }) => (active ? theme.colors?.primary : "#838383")};
  font-weight: ${({ active }) => (active ? "600" : "normal")};
`;

function MenuList1({ options, name, setLoading, onClick }) {
  const { config, setConfig } = useContext(ConfigContext);
  const theme = useContext(ThemeContext);
  const { context, setContext } = useContext(AppContext);
  const { menuCollapse, setMobileMenuOpen } = useContext(LeftBarContext);
  // const history = useHistory();
  const history = createBrowserHistory();
  const {
    location: { pathname },
  } = history;

  let _pathname = pathname.split("/").slice(-1)[0]?.toLowerCase() || "home";
  _pathname = decodeURI(_pathname.replace(/\//, "")).replace(/\-/g, " ");
  let patt = RegExp(_pathname, "i");
  console.log("patt", patt);
  let activeMenu = options?.find((menu) => patt.test(menu?.name));
  let { targetid: menuId } = activeMenu || {};

  const currentPath = pathname.split("/").slice(-1)[0]?.toLowerCase() || "home";

  useEffect(() => {
    setContext((prev) => ({ ...prev, menuId }));
  }, [pathname]);

  console.log("contextData", context);

  console.log("initial options", options);

  let groupedOrder = {};
  let optionsGroup = [];
  let optionsGroupLen = -1;

  options.forEach((o) => {
    if (o.group) {
      if (!groupedOrder[o.group]) {
        groupedOrder[o.group] = ++optionsGroupLen;
      }

      if (!optionsGroup[groupedOrder[o.group]]) {
        optionsGroup[groupedOrder[o.group]] = {
          name: o.group,
          icon: o.groupicon,
          options: [o],
        };
        return;
      }

      optionsGroup[groupedOrder[o.group]]?.options.push(o);
      return;
    }

    optionsGroup[++optionsGroupLen] = o;
  });

  console.log("optionsGroup", optionsGroup);

  // {showGroup && options.map((option) => {
  //                             let {name,icon,badge} = option
  //                             let menuIcon = iconMap[icon];
  //                             const patt = (new RegExp(`^${name}$`,"i"));
  //                             const active = patt.test(activeMenu?.name)
  //                             {icons[menuIcon] && React.createElement(icons[menuIcon], {
  //                         width:20,
  //                         height:null,
  //                         fill:active ? theme?.colors?.primary :"#AAAAAA",
  //                         stroke:active ? theme?.colors?.primary :"#AAAAAA"
  //                     })}
  //                     <MenuText className="capitalize pl-3 leading-1 text-gray-custom tracking-wide flex items-center" active={active}>
  //                         <span>myname is</span>
  //                         {typeof badge != "undefined" && <Badge className="font-semibold ml-2 rounded-sm inline-grid place-items-center rounded-xl">{badge}</Badge>}
  //                     </MenuText>
  //                         })}

  const loadPage = (item) => {
    // let postData = {
    //     "urlMenu": page.toUpperCase(),
    //     "layoutId": null,
    //     "targetId": null,
    //     "target": null,
    //     "schemaId": null,
    //     "txnGroup": null,
    //     "parentDropValue":null
    //   }

    setLoading(true);

    let postData = {
      urlMenu: null,
      layoutId: null,
      targetId: item.targetid,
      target: "MENU",
      schemaId: null,
      txnGroup: null,
      parentDropValue: null,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        let link = `${item.name.toLowerCase()}`;
        history.push(link.replace(/\s/g, "-"));
        // setContextMenuId(menuId)
        console.log("the response for loadpage", res);
        let page = { ...context.pageConfig };
        let [leftArea, MainArea, rightArea] = page.subcomponents;
        page.subcomponents = [leftArea, res, rightArea];

        setContext((prev) => ({
          ...prev,
          menuId: item.targetid,
          pageConfig: page,
        }));

        // console.log("recentPageData",page)
        // console.log("recentPageDataFromContext",page)
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const openNewPage = (item) => {
    setMobileMenuOpen(true);
    console.log(
      "the open new page function",
      item.name.toLowerCase(),
      activeMenu?.name.toLowerCase()
    );
    if (item.name.toLowerCase() === activeMenu?.name.toLowerCase()) return;
    loadPage(item);
  };

  // const setContextMenuId = (menuId) => {
  //     setContext(prev => ({...prev, menuId}))
  // }

  const iconMap = {
    home: "HomeIcon",
    setup: "SetupIcon",
    transaction: "TransactionIcon",
    approval: "ApprovalIcon",
    memo: "MemoIcon",
    open: "OpenIcon",
    dashboard: "DashboardIcon",
    confirmation: "ConfirmationIcon",
    payrollReports: "PayrollReportsIcon",
    otherReports: "OtherReportsIcon",
    essReports: "EssReportsIcon",
    bioData: "BioDataIcon",
    Online: "OnlineIcon",
    Users: "UsersIcon",
    Retail: "RetailIcon",
    Wallet: "WalletIcon",
    Ussd: "UssdIcon",
    Draws: "DrawsIcon",
    ThirdParty: "ThirdPartyIcon",
    Accuracy: "AccuracyIcon",
    Feeds: "FeedsIcon",
    Whatsapp: "WhatsappIcon",
    Error: "ErrorIcon",
    Calender: "CalenderIcon",
    map: "MapIcon",
    Statistics: "StatisticsIcon",
    OtherReports: "OtherReportsIcon",
    Ticket: "TicketIcon",
    Search: "SearchIcon",
    report: "OtherReportsIcon",
    Pending: "PendingIcon",
    pending: "PendingIcon",
    Forms: "FormsIcon",
    module: "FormsIcon",
    reports: "StatisticsIcon",
    money: "MoneyIcon",
    action: "ActionIcon",
    "": "setup",
  };

  const Tree = ({ item, current, isExpanded, toggleGroup }) => {
    const [activeItem, setActiveItem] = useState();

    const showSubOptions = (item) => {
      if (item.options) return toggleGroup(); // Toggle state
      return openNewPage(item);
    };

    const { name, icon, badge, options } = item;
    const menuIcon = iconMap[icon] || "setup";
    const patt = new RegExp(`^${name}$`, "i");
    const active = patt.test(activeMenu?.name);

    return (
      <div>
        <li
          className="mb-3 flex items-center"
          onClick={() => showSubOptions(item)}
        >
          {icons[menuIcon] &&
            React.createElement(icons[menuIcon], {
              width: 20,
              height: null,
              fill: active ? theme?.colors?.primary : "#AAAAAA",
              stroke: active ? theme?.colors?.primary : "#AAAAAA",
            })}
          <MenuText
            className={classNames(
              "capitalize leading-1 text-gray-custom tracking-wide flex items-center",
              { "pl-3": !!icons[menuIcon] }
            )}
            active={active}
          >
            <span className="pr-2">{item.name}</span>
            {typeof badge !== "undefined" && (
              <Badge className="font-semibold ml-2 rounded-sm inline-grid place-items-center rounded-xl">
                {badge}
              </Badge>
            )}
            {item.options && (
              <span className="rounded-full shadow">
                {isExpanded ? (
                  <ChevronDownIcon width={18} height={18} />
                ) : (
                  <ChevronRightIcon width={18} height={18} />
                )}
              </span>
            )}
          </MenuText>
        </li>
        <div>
          {item.options && isExpanded && (
            <TreeSub item={item.options} setActiveItem={setActiveItem} />
          )}
        </div>
      </div>
    );
  };

  const TreeSub = ({ item, setActiveItem }) => {
    return (
      <ul>
        {item.map((option, idx) => {
          let { name, icon, badge } = option;
          let menuIcon = iconMap[icon];
          const patt = new RegExp(`^${name}$`, "i");
          const active = patt.test(activeMenu?.name);
          setActiveItem(activeMenu);
          // console.log("what are the the menu items", item, active, patt);

          return (
            <li
              key={idx}
              className="mb-2 ml-6 flex items-center cursor-pointer"
              onClick={() => {
                openNewPage(option);
              }}
            >
              {icons[menuIcon] &&
                React.createElement(icons[menuIcon], {
                  width: 20,
                  height: null,
                  fill: active ? theme?.colors?.primary : "#AAAAAA",
                  stroke: active ? theme?.colors?.primary : "#AAAAAA",
                })}
              <MenuText
                className="flex capitalize pl-3 leading-1 text-gray-custom tracking-wide flex items-center"
                active={active}
              >
                <span>{name}</span>
                {typeof badge != "undefined" && (
                  <Badge className="font-semibold ml-2 rounded-sm inline-grid place-items-center rounded-xl">
                    {badge}
                  </Badge>
                )}
              </MenuText>
            </li>
          );
        })}
      </ul>
    );
  };

  const [showGroup, setShowGroup] = useState(true);
  const [expandedGroups, setExpandedGroups] = useState({});

  // Toggle function
  const toggleGroup = (index) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const showSubOptions = (item) => {
    if (item.options) return setShowGroup(!showGroup);
    return openNewPage(item);
  };

  // console.log("the menucollapse ish is ", menuCollapse);
  return (
    <div className="py-2 z-50">
      {!menuCollapse && (
        <>
          {/* <header className="font-semibold mb-3 tracking-wide">{name}</header> */}
          <header className="flex items-center mb-6 border-y border-gray-200 bg-gray-100 -mx-8 ">
            <h3 className="font-semibold tracking-wide ml-8 py-1">{name}</h3>
          </header>
          <ul>
            {optionsGroup?.map((item, idx) => (
              <li key={idx} className="mb-3 items-center cursor-pointer">
                <Tree
                  item={item}
                  current={idx}
                  isExpanded={expandedGroups[idx]}
                  toggleGroup={() => toggleGroup(idx)}
                />
              </li>
            ))}
          </ul>
        </>
      )}

      {menuCollapse && (
        <>
          {/* <header className="font-semibold mb-3 uppercase tracking-wide">{name}</header> */}
          <ul>
            {optionsGroup?.map((item, idx) => {
              let { name, icon, badge } = item;
              let menuIcon = iconMap[icon];
              // menuIcon = "SetupIcon";
              const active = new RegExp(name, "i").test(activeMenu?.name);
              return (
                <li
                  key={idx}
                  className="group mb-6 flex items-center cursor-pointer"
                  onClick={() => showSubOptions(item)}
                >
                  <div className="relative">
                    {icons[menuIcon] &&
                      React.createElement(icons[menuIcon], {
                        width: 20,
                        height: null,
                        fill: active ? theme?.colors?.primary : "#AAAAAA",
                        stroke: active ? theme?.colors?.primary : "#AAAAAA",
                      })}
                    {typeof badge != "undefined" && (
                      <Badge
                        active={active}
                        className="absolute top-0 -translate-y-3 font-semibold ml-2 rounded-sm inline-grid place-items-center rounded-xl"
                      >
                        {badge}
                      </Badge>
                    )}
                  </div>
                  {/* <div className="text-md transform origin-bottom-left transition-all duration-100 none group-hover:block group-hover:text-primary capitalize pl-3 leading-1 tracking-wide flex items-center" active={active}>
                                <span>{name}</span>
                                {typeof badge != "undefined" && <Badge className="font-semibold ml-2 rounded-sm inline-grid place-items-center rounded-xl">{badge}</Badge>}
                            </div> */}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
}

export default WithLoader(MenuList1);
