import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import profilePicture from "../assets/images/user.png";
import * as Yup from "yup";
import appFetch from "../appFetch";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import apiEndpoints from "../apiEndpoints";
import { useHistory } from "react-router-dom";
import WithLoader from "./WithLoader";

const ProfileImage = styled.div`
  width: 98px;
  height: 98px;
  border-radius: 144px;
`;

const openinfo = () => {};

function ProfileModal({ options, setLoading }) {
  let profileData = options[0];

  const [processing, setProcessing] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [detailsData, setDetailsData] = useState({});
  const [loopData, setLoopData] = useState({});
  const [theValue, setValue] = useState("");
  const usernameRef = useRef();
  const history = useHistory();

  useEffect(() => {
    setDetailsData(profileData);
  }, [profileData]);

  console.log(detailsData);
  const initialValues = {
    FullName: "",
    email: "",
    phone: "",
  };

  const validationSchema = Yup.object().shape({
    FullName: Yup.string().required("Field is required!"),
    email: Yup.string()
      .email("Invalid email address format")
      .required("Field is required!"),
    phone: Yup.string().required("Field is required!"),
  });

  // const postData = (values) =>
  //   new Promise((resolve, reject) => {
  //     setProcessing(true);
  //     appFetch.post()
  //   });

  const updateData = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    console.log(e.target);
    setDetailsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const logout = () => {
    setLoading(true);
    appFetch
      .get(apiEndpoints.logout)
      .then((res) => {
        //const loginUrl = getFromLS("loginUrl");
        if (res?.ERROR_MESSAGE === "SUCCESS") {
          localStorage.clear();
          let pathName = window.location.pathname.slice(1).split("/")[0];
          // window.location = `/${pathName}/login`
          history.push(`/${pathName}/login`);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <div className="p-6">
      <div className="mb-5">
        <ProfileImage className="overflow-hidden m-auto">
          <img src={profilePicture} className="w-full h-full" />
        </ProfileImage>
      </div>
      <div className="grid grid-cols-2 gap-10">
        {editDetails
          ? Object.keys(profileData ?? {}).map((str) => (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  alert("Form is validated! Submitting the form...");
                }}
              >
                <FormikForm>
                  <div className="inline-grid">
                    <label htmlFor={str} className="text-gray-400">
                      {str}
                    </label>
                    <input
                      id={str}
                      name={str}
                      type={str === "Email" ? "email" : "text"}
                      className="placeholder-custom-gray-100 block w-full px-1 py-2
                      border-custom-gray-200 focus:outline-none sm:text-sm hover:border rounded-tl-lg rounded-br-lg"
                      value={detailsData[str]}
                      onChange={updateData}
                      ref={usernameRef}
                    />
                    <ErrorMessage
                      name={str}
                      render={(msg) => (
                        <div className="text-xs text-red-600 mt-1">{msg}</div>
                      )}
                    />
                  </div>
                </FormikForm>
              </Formik>
            ))
          : Object.keys(profileData ?? {}).map((str) => (
              <div className="inline-grid">
                <span className="text-gray-400 text-sm font-medium">{str}</span>
                <span className="font-medium text-gray-600 truncate">
                  {(detailsData ?? {})[str]}
                </span>
              </div>
            ))}
      </div>
      <div className="mt-6 w-full text-center">
        {/* <button
          className="capitalize block text-sm bg-primary text-white rounded px-4 h-12 m-auto"
          onClick={() => setEditDetails(!editDetails)}
        >
          {editDetails ? "Save Changes" : "Edit Account"}
        </button> */}
        <button
          class="capitalize block bg-primary text-white text-sm font-medium rounded px-4 h-12 m-auto"
          onClick={logout}
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default WithLoader(ProfileModal);
