import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import icons from "../icons";
import { useTheme } from "styled-components";
import LeftBarContext from "../contexts/LeftBarContext";
import AppContext from "../contexts/AppContext";
import profilePicture from "../assets/images/user.png";
import * as Yup from "yup";
import appFetch from "../appFetch";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import apiEndpoints from "../apiEndpoints";
import { useHistory } from "react-router-dom";
import WithLoader from "./WithLoader";
// import EmptyRecord from './EmptyRecord';

const LeftBarToggler = styled.div`
  width: 32px;
  height: 32px;
  /* background: #fcfcfc; */
`;

const ProfileImage = styled.div`
  width: 98px;
  height: 98px;
  border-radius: 144px;
`;

function ProfileList1({ setLoading, children, name, options }) {
  const theme = useTheme();
  const { context } = useContext(AppContext);
  const { menuCollapse, setMenuCollapse, setMobileMenuOpen } =
    useContext(LeftBarContext);

  let profileData = options[0];
  const [processing, setProcessing] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [detailsData, setDetailsData] = useState({});
  const [loopData, setLoopData] = useState({});
  const [theValue, setValue] = useState("");
  const usernameRef = useRef();
  const history = useHistory();

  useEffect(() => {
    setDetailsData(profileData);
  }, [profileData]);

  console.log(detailsData);
  const initialValues = {
    FullName: "",
    email: "",
    phone: "",
  };

  const validationSchema = Yup.object().shape({
    FullName: Yup.string().required("Field is required!"),
    email: Yup.string()
      .email("Invalid email address format")
      .required("Field is required!"),
    phone: Yup.string().required("Field is required!"),
  });

  const updateData = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    console.log(e.target);
    setDetailsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const logout = () => {
    setLoading(true);
    appFetch
      .get(apiEndpoints.logout)
      .then((res) => {
        //const loginUrl = getFromLS("loginUrl");
        if (res?.ERROR_MESSAGE === "SUCCESS") {
          localStorage.clear();
          let pathName = window.location.pathname.slice(1).split("/")[0];
          // window.location = `/${pathName}/login`
          history.push(`/${pathName}/login`);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <div>
      {/* {!menuCollapse && <hr className="-mx-8 -mt-2 mb-2" />} */}

      {menuCollapse && (
        <LeftBarToggler
          className="hidden cursor-pointer md:inline-grid place-items-center"
          onClick={() => setMenuCollapse(!menuCollapse)}
        >
          {React.createElement(icons["FileIcon"], {
            stroke: "gray",
            width: "22px",
            height: "22px",
          })}
        </LeftBarToggler>
      )}

      {!menuCollapse && (
        <>
          <div className="py-10">
            <header className="flex items-center mb-3 border-y border-gray-200 bg-gray-100 -mx-8 ">
              <h3 className="capitalize font-semibold tracking-wide ml-8 py-1">
                {name}
              </h3>
            </header>

            <div className="p-6">
              <div className="mb-5">
                <ProfileImage className="overflow-hidden m-auto">
                  <img src={profilePicture} className="w-full h-full" />
                </ProfileImage>
              </div>
              <div className="grid space-y-5">
                {editDetails
                  ? Object.keys(profileData ?? {}).map((str) => (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                          alert("Form is validated! Submitting the form...");
                        }}
                      >
                        <FormikForm>
                          <div className="inline-grid">
                            <label htmlFor={str} className="text-gray-400">
                              {str}
                            </label>
                            <input
                              id={str}
                              name={str}
                              type={str === "Email" ? "email" : "text"}
                              className="placeholder-custom-gray-100 block w-full px-1 py-2
                      border-custom-gray-200 focus:outline-none sm:text-sm hover:border rounded-tl-lg rounded-br-lg"
                              value={detailsData[str]}
                              onChange={updateData}
                              ref={usernameRef}
                            />
                            <ErrorMessage
                              name={str}
                              render={(msg) => (
                                <div className="text-xs text-red-600 mt-1">
                                  {msg}
                                </div>
                              )}
                            />
                          </div>
                        </FormikForm>
                      </Formik>
                    ))
                  : Object.keys(profileData ?? {}).map((str) => (
                      <div className="inline-grid">
                        <span className="text-gray-400 text-sm font-medium">
                          {str}
                        </span>
                        <span className="font-medium text-gray-600 truncate">
                          {(detailsData ?? {})[str]}
                        </span>
                      </div>
                    ))}
              </div>
              <div className="mt-6 w-full text-center">
                <button
                  class="capitalize block bg-primary text-white text-sm font-medium rounded px-4 h-12 m-auto"
                  onClick={logout}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default WithLoader(ProfileList1);
