import React, { useContext } from "react";
import styled from "styled-components";
import AppContext from "../contexts/AppContext";

const PreviewContainer = styled.div`
  width: 100%;
  max-width: 960px;
  background: #fff;
  padding: 16px;
  overflow-y: auto;
  max-height: 85vh;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 95vw;
    max-height: 80vh;
    padding: 8px;
  }
`;

const filterPropsByName = (namesToRemove, sourceArray) => {
  return sourceArray.filter((c) => !namesToRemove.includes(c.name));
};

const getObjectByName = (name, sourceArray) => {
  let item = sourceArray.find((o) => o.name === name);
  return item || {};
};

function PrintPreview({ data }) {
  const { context } = useContext(AppContext);
  const {
    summary,
    amount,
    buyer_seller,
    company,
    currency,
    status,
    document,
    remark,
  } = data;

  const lines = data?.line_data;
  let header = (data?.line_header ?? [])[0]?.name;
  const attachments = (data?.attachment ?? []).map(({ name }) => name)?.flat();
  header = header?.filter((m) => !/download|attachment/i.test(m));

  console.log("print preview amount", amount);

  return (
    <PreviewContainer>
      <div className="mt-2">
        <div className="border border-color-primary rounded-md overflow-hidden">
          <div id="print-preview">
            {/* Status and Document Info */}
            <div className="flex flex-col sm:flex-row bg-gray-100 justify-between px-3 sm:px-10 w-full py-4 sm:py-6 mb-3">
              <div className="bg-primary p-2 sm:p-4 flex flex-col w-full sm:w-48 self-start">
                <span className="text-xs sm:text-sm text-white capitalize">
                  status
                </span>
                <span className="text-sm sm:text-lg text-white capitalize">
                  {status[0]?.value}
                </span>
              </div>
              <div className="grid w-full sm:w-48 mt-3 sm:mt-0">
                <span className="text-sm sm:text-lg uppercase mb-2">
                  {document[0]?.value}
                </span>
                <div className="grid gap-1 sm:gap-2">
                  <div className="flex flex-col">
                    <span className="text-primary font-medium text-xs sm:text-sm">
                      {document[1]?.name}:
                    </span>
                    <span className="text-gray-500 text-xs sm:text-sm">
                      {document[1]?.value}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-primary font-medium text-xs sm:text-sm">
                      Date:
                    </span>
                    <span className="text-gray-500 text-xs sm:text-sm">
                      {document[2]?.value}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Company and Buyer/Seller Info */}
            <div className="flex flex-col md:flex-row justify-between px-4 md:px-8 lg:px-20 w-full mb-4">
              {[company, buyer_seller].map((entity, idx) => {
                const logo =
                  idx === 1
                    ? context?.app?.logo?.[0] ||
                      getObjectByName("Logo", entity)?.value
                    : getObjectByName("Logo", entity)?.value;
                const email = getObjectByName("Email", entity);
                const phoneNumber = getObjectByName("Phone Number", entity);
                const others = filterPropsByName(
                  ["Logo", "Email", "Phone Number"],
                  entity
                );
                const unique = [...new Set(others.map((item) => item.value))];

                return (
                  <div key={idx} className="w-full md:w-60">
                    {logo && (
                      <div className="logo">
                        <img
                          src={logo}
                          className="max-w-full h-auto"
                          alt="Company Logo"
                        />
                      </div>
                    )}
                    <div className="grid">
                      {unique.map((value, index) => (
                        <span key={index} className="text-gray-600">
                          {value}
                        </span>
                      ))}
                      {email.value && (
                        <div className="mb-2 grid">
                          <span className="font-medium text-black">Email</span>
                          <span className="text-gray-600">{email.value}</span>
                        </div>
                      )}
                      {phoneNumber.value && (
                        <div className="mb-2 grid">
                          <span className="font-medium text-black">Phone</span>
                          <span className="text-gray-600">
                            {phoneNumber.value}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* Currency Section */}
            <div className="bg-gray-400 flex justify-between px-4 md:px-8 lg:px-20 items-center h-10">
              <div className="text-white">Currency: ({currency[0]?.value})</div>
              <div className="text-white">
                Exchange Rate: {currency[1]?.value}
              </div>
            </div>

            {/* Table Section */}
            <div className="overflow-x-auto px-4 md:px-8">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    {header?.map((str, idx) => (
                      <th
                        key={idx}
                        className="px-5 py-3 border-b-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase"
                      >
                        {str.replace(/_+/g, " ")}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {lines?.map((row, idx) => (
                    <tr key={idx}>
                      {header?.map((str, index) => (
                        <td
                          key={index}
                          className="px-5 py-5 border-gray-200 bg-white text-sm"
                        >
                          <span
                            className="text-gray-900 whitespace-no-wrap"
                            dangerouslySetInnerHTML={{
                              __html: row[str.toUpperCase()],
                            }}
                          ></span>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Amount Section */}
            <div className="flex justify-end px-4 md:px-8 lg:px-5 mt-2">
              <div className="w-full sm:w-2/3 md:w-1/2 bg-gray-100 rounded-lg p-4 border border-gray-300">
                {amount?.map((item, idx) => (
                  <div
                    key={idx}
                    className="flex justify-between py-2 px-3 border-b last:border-none"
                  >
                    <span className="font-medium text-gray-700">
                      {item?.name}
                    </span>
                    <span
                      className="text-gray-900 font-semibold"
                      dangerouslySetInnerHTML={{ __html: item?.value }}
                    ></span>
                  </div>
                ))}
              </div>
            </div>

            {/* Remarks */}
            <div className="mt-10 px-4 md:px-8 lg:px-20">
              <p className="capitalize font-semibold">Remarks</p>
              <p className="text-gray-400">{remark?.value ?? "No remark"}</p>
            </div>

            {/* Attachments */}
            {attachments.length > 0 && (
              <div className="mt-4 px-4 md:px-8 lg:px-20 bg-gray-100 py-8">
                <p className="font-semibold">Uploads</p>
                <div className="flex gap-4">
                  {attachments.map((url, idx) => (
                    <div
                      key={idx}
                      className="rounded-lg overflow-hidden border w-16 h-16 p-1 cursor-pointer hover:scale-110"
                    >
                      <a href={url} target="_blank" rel="noreferrer">
                        <img
                          className="w-full h-full object-cover"
                          src={url}
                          alt=""
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Footer */}
            <div className="bg-gray-400 flex justify-between px-4 md:px-8 lg:px-20 items-center min-h-10 leading-none py-4">
              <div className="text-white">
                Thanks for being a Swift NG Limited customer
              </div>
              <div className="text-white">Need help? help@swift.com.ng</div>
            </div>
          </div>
        </div>
      </div>
    </PreviewContainer>
  );
}

export default PrintPreview;
