import React from "react";
import renderComponent from "../renderComponent";

function MiddleArea1_2({ subcomponents, filterOption, accessOption }) {
  console.log("the children are", subcomponents, filterOption, accessOption);
  return (
    <div>
      {subcomponents?.map((config, idx) =>
        renderComponent(config, {
          filterOption,
          accessOption,
        })
      )}
    </div>
  );
}

export default MiddleArea1_2;
