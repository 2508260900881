import * as React from "react";

const FundWalletIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 32 32"
    enable-background="new 0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <desc />

    <g>
      <path
        d="M22,5H5   C3.895,5,3,5.895,3,7v22c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V18"
        fill="none"
        stroke={props?.stroke}
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3"
      />

      <path
        d="M9,14H3v8h6   c2.209,0,4-1.791,4-4v0C13,15.791,11.209,14,9,14z"
        fill="none"
        stroke={props?.stroke}
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3"
      />

      <circle cx="9" cy="18" r="2" />

      <line
        fill="none"
        stroke={props?.stroke}
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3"
        x1="25"
        x2="25"
        y1="0"
        y2="15"
      />

      <polyline
        fill="none"
        points="19,9 25,15 31,9     "
        stroke={props?.stroke}
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3"
      />
    </g>
  </svg>
);

export default FundWalletIcon;
