import React, { useContext, useEffect, useState } from "react";
import renderComponent from "../renderComponent";
import classNames from "classnames";
import AppContext from "../contexts/AppContext";
import { createBrowserHistory } from "history";
import WithLoader from "./WithLoader";
import appFetch from "../appFetch";
import { appendQueryToUri } from "../util/generalUtil";
import apiEndpoints from "../apiEndpoints";
import moment from "moment";
import Spinner from "../loaders/Spinner";
import { CalendarIcon } from "@heroicons/react/solid";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";

window._moment = moment;

const layoutRule = [
  "md:col-start-1 md:col-end-2", // First column for md and above
  "md:col-start-2 md:col-end-3", // Second column for md and above
  "md:col-start-1 md:col-end-3", // Full width for md and above
];
const testData = {
  options: [
    {
      icon: "user",
      layoutid: 11570,
      name: "Today",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11571,
      name: "Yesterday",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "2 Days Ago",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "Last 7 days",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "Last 30 days",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "This Month",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "Last Month",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "This Year",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "Last Year",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "Custom",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
  ],
  type: "ParentMainArea2",
  subcomponents: {},
};

const testData2 = {
  options: [
    {
      icon: "user",
      layoutid: 11570,
      name: "Daily",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11571,
      name: "Monthly",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "Quarterly",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "Yearly",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
  ],
  type: "ParentMainArea2",
  subcomponents: {},
};

const testData3 = {
  options: [
    {
      icon: "user",
      layoutid: 11570,
      name: "2024",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11571,
      name: "2023",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "2022",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "2021",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "2020",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "2019",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
  ],
  type: "ParentMainArea2",
  subcomponents: {},
};

const testData4 = {
  options: [
    {
      icon: "user",
      layoutid: 11570,
      name: "Today",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11571,
      name: "Yesterday",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "2 Days Ago",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "3 Days Ago",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "4 Days Ago",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11572,
      name: "5 Days Ago",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11573,
      name: "6 Days Ago",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
    {
      icon: "user",
      layoutid: 11574,
      name: "7 Days Ago",
      records_per_page: 40,
      target: "datenotifcation",
      targetid: "2290",
    },
  ],
  type: "ParentMainArea2",
  subcomponents: {},
};

const ReportOption = ({ innerProps, data, children, label }) => {
  return (
    <div className="custom-select-option" {...innerProps}>
      {children}
    </div>
  );
};

const ReportOptionValue = ({ children, data }) => {
  return <div className="custom-select-option-value">{children}</div>;
};

function ParentMerge2MiddleArea({ setLoading, subcomponents }) {
  const {
    context: { menuId, pageConfig },
  } = useContext(AppContext);
  const history = createBrowserHistory();
  const {
    location: { pathname },
  } = history;

  let _pathname = pathname.split("/").slice(-1)[0]?.toLowerCase() || "home";
  _pathname = decodeURI(_pathname.replace(/\//, "")).replace(/\-/g, " ");
  let patt = RegExp(_pathname, "i");
  console.log("patt mainArea", patt);

  let menuList =
    pageConfig?.subcomponents[0]?.subcomponents[0]?.subcomponents[1]?.options;

  let activeMenu = menuList?.find((menu) => patt.test(menu?.name));

  // console.log("the menulist and activeMenu", menuList, activeMenu);

  let date_filter = activeMenu?.date_filter;
  let access_filter = activeMenu?.access_filter;
  let scroll = activeMenu?.scroll;

  const [newsubscomponents, setNewsubcomponents] = useState(subcomponents);
  const [activeReport, setActiveReport] = useState(null);

  const [activePeriod, setActivePeriod] = useState(null);
  const [activePeriodType, setActivePeriodType] = useState(null);
  const [dataGrid, setDataGrid] = useState(null);

  const [activeAccessFilter, setActiveAccessFilter] = useState(null);
  const [accessFilterData, setAccessFilterData] = useState(null);

  const [activeDateFilter, setActiveDateFilter] = useState(null);
  const [dateFilterData, setDateFilterData] = useState(null);

  const [dateRange, setDateRange] = useState(null);
  const [dateRange2, setDateRange2] = useState(null);
  const [filterDateRange, setFilterDateRange] = useState(null);
  const [loadDate, setLoadDate] = useState(false);

  const currentYear = new Date().getFullYear();

  const minDate = new Date(currentYear - 1, 0, 1);
  const maxDate = new Date(currentYear, 11, 31);

  console.log("the activeMenu is ", activeMenu);

  console.log(
    "the add_filter value and subcomponents",
    newsubscomponents,
    date_filter,
    access_filter
  );

  const [filterOption, setFilterOption] = useState(null);
  const [accessOption, setAccessOption] = useState(null);

  useEffect(() => {
    if (menuId) {
      setNewsubcomponents(subcomponents);
      setActiveReport(activeReport);
      setActivePeriodType(activePeriodType);
      setActivePeriod(activePeriod);

      console.log("Updated context detected, recalculating state:", {
        activeReport,
        dataGrid,
      });
    }
  }, [menuId]);

  useEffect(() => {
    if (access_filter) {
      fetchAcessFilterDropdownData();
    }

    if (date_filter) {
      fetchDateFilterDropdownData();
    }
  }, [access_filter]); // Runs whenever access_filter changes

  const fetchAcessFilterDropdownData = () => {
    setLoading(true);
    let postData = {
      target: access_filter,
      targetId: activeMenu?.targetid,
    };
    console.log("the access_filter postdata", postData);

    appFetch
      .get(appendQueryToUri(apiEndpoints.dropdownView, postData))
      .then((res) => {
        console.log("the access_filter options are: ", res);
        setAccessFilterData(res);
        setActiveAccessFilter(res?.[0]);
        setAccessOption((res ?? [])[0]?.value);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const fetchDateFilterDropdownData = () => {
    setLoading(true);
    let postData = {
      target: date_filter,
      targetId: activeMenu?.targetid,
    };
    console.log("the date_filter postdata", postData);

    appFetch
      .get(appendQueryToUri(apiEndpoints.dropdownView, postData))
      .then((res) => {
        console.log("the date_filter options are: ", res);
        setDateFilterData(res);
        setActiveDateFilter(res?.[0]);
        setFilterOption((res ?? [])?.[0]?.value);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const filterByDateRange = (dateFilter) => {
    setLoading(true);

    let postData = {
      layoutId: "",
      schemaId: "",
      target: "MENU",
      targetId: activeMenu?.targetid,
      dateFilter: dateFilter ? JSON.stringify(dateFilter) : null,
    };

    setFilterOption(JSON.stringify(dateFilter));
    console.log("the filterbyDate postDATA", postData);

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        let newsubcom = res?.subcomponents;
        console.log("New menu layout", res, postData);
        console.log(
          "the current subcomponents and new update",
          newsubscomponents,
          newsubcom
        );
        console.log(
          "the add_filter value and subcomponents new page",
          res,
          postData,
          newsubcom
        );
        setNewsubcomponents(newsubcom);
      })
      .catch((e) => console.log(e.message))
      .finally(() => {
        setLoading(false);
        setLoadDate(false);
      });
  };

  const fetchReportNewHomeLayout = (reportType, secondaryReport) => {
    setLoading(true);

    console.log("the current report type is", reportType);

    if (reportType.name == "Custom") {
      setLoading(false);
      return;
    }

    let postData = {
      layoutId: "",
      schemaId: "",
      target: "MENU",
      targetId: activeMenu?.targetid,
      dateFilter: reportType.name,
      accessFilter: secondaryReport ? secondaryReport.value : null,
      // periodFilter: date_filter ? date_filter : null,
    };

    setFilterOption(reportType.name);
    setAccessOption(secondaryReport?.value);

    console.log("new HomeScreen postData", postData);

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        let newsubcom = res?.subcomponents;
        console.log("New menu layout", res, postData);
        console.log(
          "the add_filter value and subcomponents new page",
          res,
          postData,
          newsubcom
        );
        setNewsubcomponents(newsubcom);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const fetchAccessNewHomeLayout = (reportType, secondaryReport) => {
    setLoading(true);

    console.log("the current report type is", reportType);

    if (reportType.name == "Custom") {
      setLoading(false);
      return;
    }

    let postData = {
      layoutId: "",
      schemaId: "",
      target: "MENU",
      targetId: activeMenu?.targetid,
      dateFilter: secondaryReport?.name,
      accessFilter: reportType ? reportType.value : null,
      // periodFilter: date_filter ? date_filter : null,
    };

    setFilterOption(secondaryReport.name);
    setAccessOption(reportType?.value);

    console.log("new HomeScreen postData", postData);

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        let newsubcom = res?.subcomponents;
        console.log("New menu layout", res, postData);
        console.log(
          "the add_filter value and subcomponents new page",
          res,
          postData,
          newsubcom
        );
        setNewsubcomponents(newsubcom);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const ondateFilterChange = (selectedOption) => {
    setActiveDateFilter(selectedOption);
    fetchReportNewHomeLayout(selectedOption, activeAccessFilter);
  };

  const onaccessFilterChange = (selectedOption) => {
    console.log("the selected Option is", selectedOption);
    setActiveAccessFilter(selectedOption);
    fetchAccessNewHomeLayout(selectedOption, activeDateFilter);
  };

  let filteredDatefilter = subcomponents?.filter(
    (component) =>
      component.type === "DateNotificationList1" ||
      component.type === "DateNotificationList2"
  );

  let DateReportOptions =
    (filteredDatefilter ?? [])[0]?.options?.map(
      ({ period, grid, ...rest }) => ({
        ...rest,
        label: rest.name,
      })
    ) || [];

  let initialActiveReport = DateReportOptions[0] ?? null;
  let datefilterOptions = (filteredDatefilter ?? [])[0]?.options;

  console.log("the children of ParentMerge2MiddleArea", subcomponents);

  console.log("children of parent merge 2", subcomponents);

  console.log("the date filter data is ", date_filter);

  return (
    <>
      <div
        className={`lg:px-8 px-4 pb-8 pt-14 lg:pt-8 w-rb overflow-auto ${
          scroll === "yes"
            ? " scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
            : ""
        }`}
      >
        <div className="flex-col">
          <div
            className={`border-bottom flex ${
              access_filter ? "justify-between" : "justify-end"
            }  items-center rounded-md py-2`}
          >
            {access_filter && (
              <div className="flex gap-2 sm:w-auto">
                <Select
                  className="w-full sm:w-auto"
                  classNamePrefix="custom-select-option"
                  components={{
                    Option: ReportOption,
                    SingleValue: ReportOptionValue,
                  }}
                  value={activeAccessFilter}
                  isClearable={false}
                  onChange={onaccessFilterChange}
                  options={accessFilterData}
                />
              </div>
            )}

            {date_filter && (
              <div className="flex gap-2 sm:w-auto">
                <Select
                  className="w-full sm:w-auto"
                  classNamePrefix="custom-select-option"
                  components={{
                    Option: ReportOption,
                    SingleValue: ReportOptionValue,
                  }}
                  value={activeDateFilter}
                  isClearable={false}
                  onChange={ondateFilterChange}
                  options={dateFilterData}
                />
              </div>
            )}
          </div>
        </div>
        {activeReport?.name === "Custom" && (
          <div className="block md:flex md:space-x-5 items-center">
            <div className="flex items-center justify-between border-2 border-gray-300 bg-white h-10 pl-3 rounded-lg text-sm focus:outline-none">
              <ReactDatePicker
                selectsRange={false}
                minDate={minDate}
                maxDate={maxDate}
                wrapperClassName="bg-transparent focus:outline-none"
                dateFormat={"MMM, d"}
                selected={dateRange}
                onChange={(update) => {
                  setDateRange(update);
                }}
                placeholderText="Start date"
                clearButtonClassName="text-primary hover:bg-primary rounded-md"
                isClearable={true}
                clear="close"
              />
              <div className="flex-1 pr-2">
                <CalendarIcon width={20} height={20} fill="#9e9e9e" />
              </div>
            </div>

            <h2>-</h2>

            <div className="flex items-center justify-between border-2 border-gray-300 bg-white h-10 pl-3 rounded-lg text-sm focus:outline-none mb-5 md:mb-0">
              <ReactDatePicker
                selectsRange={false}
                minDate={dateRange ?? minDate}
                maxDate={maxDate}
                wrapperClassName="bg-transparent focus:outline-none"
                dateFormat={"MMM, d"}
                selected={dateRange2}
                onChange={(update) => {
                  setDateRange2(update);
                }}
                placeholderText="End date"
                clearButtonClassName="text-primary hover:bg-primary rounded-md"
                isClearable={true}
                clear="close"
              />
              <div className="flex-1 pr-2">
                <CalendarIcon width={20} height={20} fill="#9e9e9e" />
              </div>
            </div>

            <button
              disabled={!dateRange && !dateRange2 ? true : false}
              onClick={() => {
                if (moment(dateRange)._isValid && moment(dateRange2)._isValid) {
                  const dateFilter = {
                    startDate: moment(dateRange).format("yyyy-MM-D"),
                    endDate: moment(dateRange2).format("yyyy-MM-D"),
                  };
                  setLoadDate(true);
                  setFilterDateRange(dateFilter);
                  filterByDateRange(dateFilter);
                }

                if (
                  !moment(dateRange)._isValid &&
                  !moment(dateRange2)._isValid
                ) {
                  setFilterDateRange(null);
                }
              }}
              className={`${
                dateRange && dateRange2
                  ? "bg-primary "
                  : "bg-gray-300 text-black"
              }hover:bg-primary text-white tracking-normal text-center font-semibold text-xs py-[10px] px-4 rounded-lg inline-flex items-center`}
              // style={{ borderRadius: "1rem" }}
            >
              <h3 className="text-sm">
                {loadDate ? (
                  <span className="">
                    {" "}
                    <Spinner fill="#e2e3e4" width={18} height={18} />
                  </span>
                ) : (
                  <span className="">Search</span>
                )}
              </h3>
            </button>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 h-full flex-1 p-4 overflow-auto">
          {newsubscomponents.map((config, idx) => (
            <section
              key={idx}
              className={classNames(layoutRule[idx], "w-full")}
            >
              {renderComponent(config, {
                filterOption,
                initialActiveReport,
                filteredDatefilter,
                accessOption,
              })}
            </section>
          ))}
        </div>
      </div>
    </>
  );
}

export default WithLoader(ParentMerge2MiddleArea);
