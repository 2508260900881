import React from "react";
import renderComponent from "../renderComponent";

function MiddleArea1_3({ subcomponents, filterOption, accessOption }) {
  console.log("the children are", subcomponents, filterOption, accessOption);
  return (
    <div className="flex-1 h-full overflow-y-auto scrollbar scrollbar-h-1 scrollbar-track-gray-200 scrollbar-thumb-gray-500 scrollbar-thumb-rounded-full p-4">
      {subcomponents?.map((config, idx) =>
        renderComponent(config, {
          filterOption,
          accessOption,
        })
      )}
    </div>
  );
}

export default MiddleArea1_3;
