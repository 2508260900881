import React, { useContext, useState } from "react";
import AppContext from "../contexts/AppContext";
import renderComponent from "../renderComponent";

function ChartWrapperControl1({ subcomponents, filterOption, accessOption }) {
  console.log("subcomponents");
  const [selectedChartControl, setSelectedChartControl] = useState(null);

  let [chartFilter, chartControl] = subcomponents;
  let customSubcomponents = [chartFilter, selectedChartControl];

  let subcomponentstoUse = [];

  if (selectedChartControl) {
    subcomponentstoUse = customSubcomponents;
  } else {
    subcomponentstoUse = subcomponents;
  }

  console.log("the subcomponentstoUse", subcomponentstoUse, filterOption);

  return (
    <>
      {subcomponentstoUse?.map((config, idx) =>
        renderComponent(config, {
          selectedChartControl,
          setSelectedChartControl,
          filterOption,
          accessOption,
        })
      )}
    </>
  );
}

export default ChartWrapperControl1;
