import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import LogoutIcon from "../icons/LogoutIcon";
import styled from "styled-components";
import WithLoader from "./WithLoader";
import renderComponent from "../renderComponent";
import AppContext from "../contexts/AppContext";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import SidebarModal from "./SidebarModal";
import formData from "../samples/role.json";
import { getFromLS } from "../util/storage";
import { useHistory } from "react-router-dom";
import { appendQueryToUri } from "../util/generalUtil";
import icons from "../icons";
import ConfirmDialog from "./ConfirmDialog";
import GridAndFormWrapper2 from "./GridAndFormWrapper2";
import AddWalletModal from "./AddWalletModal";

const Role = styled.div`
  background: ${({ theme }) => theme?.colors.primary};
`;

const iconMap = {
  fundWallet: "FundWalletIcon",
  withdraw: "WithdrawIcon",
  pay: "WalletPaymentIcon",
  walletHistory: "HistoryIcon",
  reset: "ResetIcon",
  add: "AddIcon",
};
// How will I handle wallet type and charges

// The usual logic is that this is gotten when the user selects an option from the dropdown in settings

const walletForms = [
  {
    name: "Fund Wallet",
    data: [
      {
        type: "WalletForms",
        showPreview: "false",
        useCart: "false",
        name: "Fund Wallet",
        button_action: "FUND WALLET",
        button_hide: "false",
        schema: [
          {
            options: [
              {
                label: "information text",
                type: "info",
                name: "info",
                text: "You will be charged for this transaction",
              },
              {
                data: "WALLET TYPE",
                label: "Wallet",
                name: "wallet type",
                root_1: "_WALLET",
                root_2: "FUND_WALLET",
                target: "select",
                type: "select",
                validation: { required: true },
              },
              {
                label: "Amount",
                name: "amount",
                target: "number",
                type: "number",
                validation: { required: true },
              },
              {
                label: "Total Amount",
                name: "total amount",
                target: "text",
                script: {
                  field: "amount",
                  operation: "addition",
                  amount: "5000",
                },
                type: "serverprop",
                validation: { required: true },
              },
              {
                label: "Pin",
                name: "pin",
                target: "hidden_text",
                type: "pin",
                validation: { required: true },
              },
            ],
            submit: { subcomponent: ["Save"] },
          },
        ],
        target_id: 467833,
      },
    ],
  },
  {
    name: "Withdraw",
    data: [
      {
        type: "WalletForms",
        showPreview: "false",
        useCart: "false",
        name: "Fund Wallet",
        button_action: "WITHDRAW",
        button_hide: "false",
        schema: [
          {
            options: [
              {
                data: "WALLET TYPE",
                label: "Wallet",
                name: "wallet type",
                root_1: "_WALLET",
                root_2: "FUND_WALLET",
                target: "select",
                type: "select",
                validation: { required: true },
              },
              {
                label: "Amount",
                name: "amount",
                target: "number",
                type: "number",
                validation: { required: true },
              },
              {
                label: "Pin",
                name: "pin",
                target: "hidden_text",
                type: "pin",
                validation: { required: true },
              },
            ],
            submit: { subcomponent: ["Save"] },
          },
        ],
        target_id: 467833,
      },
    ],
  },
];

const WalletControlModal = ({
  options,
  loadForm,
  setLoading,
  ConfirmDialog,
  setConfirmDialog,
}) => {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const logout = () => {
    setLoading(true);
    appFetch
      .get(apiEndpoints.logout)
      .then((res) => {
        //const loginUrl = getFromLS("loginUrl");
        if (res?.ERROR_MESSAGE === "SUCCESS") {
          localStorage.clear();
          let pathName = window.location.pathname.slice(1).split("/")[0];
          // window.location = `/${pathName}/login`
          history.push(`/${pathName}/login`);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const resetPinReq = (item) => {
    setLoading(true);
    // setInProp(true);

    let postData = {
      phoneNumber: "08179057040",
      walletType: "wema",
    };

    // setForm(formData)x`
    // setLoading(false)

    appFetch
      .post("https://hts-wallet.azurewebsites.net/wallet/forgot-p", {
        body: JSON.stringify(postData),
      })
      .then((res) => {
        console.log("response from forgot pin request", res);
        // setWalletData(res);
        loadForm(item);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="pt-3 px-3 rounded-lg overflow-hidden z-12">
        <div class="grid w-44">
          {options?.map((item, idx) => {
            let menuIcon = iconMap[item.icon];

            return (
              <>
                <div
                  key={idx}
                  className="flex items-center justify-between px-2 cursor-pointer"
                  onClick={() => {
                    if (item?.name === "Reset Pin") {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure you want to proceed?",
                        subtitle: "You can't undo this operation",
                        onConfirm: async () => {
                          await loadForm(item);
                        },
                      });
                    } else {
                      loadForm(item);
                    }
                    console.log("the clicked item", item);
                  }}
                >
                  <div className="border-color-gray-300 py-2">
                    <span className="text-sm">{item.name}</span>
                  </div>

                  <div class="cursor-pointer py-2">
                    {icons[menuIcon] &&
                      React.createElement(icons[menuIcon], {
                        width: 20,
                        height: null,
                        fill: "#1a1a1aaa",
                        stroke: "#1a1a1aaa",
                        // fill: theme?.colors?.primary,
                        // stroke: theme?.colors?.primary,
                      })}
                  </div>
                </div>
                <div className="border-b border-color-gray-300" />
              </>
            );
          })}
          {/* <div
            class="border-b cursor-pointer bg-primary text-white border-color-gray-300 py-2 -mx-3 p-3"
            onClick={logout}
          >
            <span className="text-sm">Logout</span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export const WalletControlForm2 = ({ item, updateFormState, setLoading }) => {
  const theme = useContext(ThemeContext);
  const [inProp, setInProp] = useState(false);
  const [form, setForm] = useState(null);
  const { context } = useContext(AppContext);

  const { name } = item;

  console.log("WalletControlForm name => ", item);

  useEffect(() => {
    setLoading(true);
    setInProp(true);

    let postData = {
      urlAppCode: null,
      urlMenuName: null,
      layoutId: item?.layoutid,
      targetId: item?.targetid,
      target: item?.target?.toUpperCase(),
      applicationId: context?.applicationId,
      schemaId: item?.schemaid,
      txnGroup: null,
      parentDropValue: null,
    };

    // setForm(formData)
    // setLoading(false)

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((result) => {
        let form = result;
        console.log("value of result from settingsForm - walletList", result);
        setForm(form);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {form &&
        renderComponent(form, {
          layoutid: item.layoutid,
          setInProp,
          updateFormState,
          inProp,
          setLoading
        })}
    </>
  );
};

export const WalletControlForm = ({
  item,
  updateFormState,
  isOpen,
  openModal,
  closeModal,
}) => {
  const theme = useContext(ThemeContext);
  const [inProp, setInProp] = useState(false);
  const [form, setForm] = useState(null);
  const { context } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const { name } = item;

  console.log("WalletControlForm name => ", item);

  // useEffect(() => {
  //   setLoading(true);
  //   setInProp(true);

  //   setTimeout(() => {
  //     let sortForms;
  //     sortForms = walletForms.filter((form) => form.name === item.name);

  //     setForm(sortForms[0]?.data[0]);
  //     console.log("the sorted form is", sortForms);
  //     setLoading(false);
  //   }, 1500);

  //   //   let postData = {
  //   //     urlAppCode: null,
  //   //     urlMenuName: null,
  //   //     layoutId: item?.layoutid,
  //   //     targetId: item?.targetid,
  //   //     target: item?.target?.toUpperCase(),
  //   //     applicationId: context?.applicationId,
  //   //     schemaId: item?.schemaid,
  //   //     txnGroup: null,
  //   //     parentDropValue: null,
  //   //   };

  //   //   appFetch
  //   //     .get(appendQueryToUri(apiEndpoints.menuView, postData))
  //   //     .then((result) => {
  //   //       let form = result;
  //   //       console.log("value of result", result);
  //   //       setForm(form);
  //   //     })
  //   //     .catch((e) => console.log(e.message))
  //   //     .finally(() => setLoading(false));
  // }, []);

  useEffect(() => {
    setLoading(true);
    setInProp(true);

    let postData = {
      urlAppCode: null,
      urlMenuName: null,
      layoutId: item?.layoutid,
      targetId: item?.targetid,
      target: item?.target?.toUpperCase(),
      applicationId: context?.applicationId,
      schemaId: item?.schemaid,
      txnGroup: null,
      parentDropValue: null,
    };

    // setForm(formData)
    // setLoading(false)

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((result) => {
        let form = result;
        console.log("value of result from settingsForm", result);
        setForm(form);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  }, []);

  return (
    <SidebarModal
      inProp={inProp}
      closeSidebar={() => {
        updateFormState();
        setInProp(false);
      }}
      loading={loading}
    >
      {/* <div>Open Wallet Sidebar</div> */}
      {/* Things to do */}
      {/* Create a walletForms components - it would work like the form1 component */}
      {/* I might have to create formComponents also - to be decided */}
      {/* So I will create a hardcoded JSON that will call this component  */}
      {/* The walletForms component will handle the json and return a form to the screen */}
      {/* Then I can handle the submit functionality on the walletForms also*/}

      {form &&
        renderComponent(form, {
          layoutid: item.layoutid,
          isOpen,
          openModal,
          closeModal,
          updateFormState,
          inProp,
          setInProp,
        })}

      {/* {form && (
        <GridAndFormWrapper2
          form={form}
          isOpen={isOpen}
          openModal={openModal}
          closeModal={closeModal}
        />
      )} */}
    </SidebarModal>
  );
};

export default WithLoader(WalletControlModal);
