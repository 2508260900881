import React, { useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { object } from "yup";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";
import moment from "moment"
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import { formatNumber } from "../util/formatter";
import { mapOperator } from "../util";
import * as Yup from "yup";
import {
  Formik,
  Form as FormikForm
} from "formik";
import { appendQueryToUri, isValidValue } from "../util/generalUtil";


import {
  InputField,
  SelectField,
  RadioField,
  LabelField,
  FileField,
  RichTextField,
  PhoneField,
  StyledDate,
  NumberField,
  ColorPicker,
  UrlField,
  ServerPropField,
  LocationField,
  CheckboxField,
} from "./FormElements";
import AppContext from "../contexts/AppContext";
import { mapValidationSchema } from "../formValidation";
import { defaults } from "../mapInitialValues";


function mapField(type) {
  switch (type) {
    case "select":
    case "multiselect":
      return SelectField;
    case "radio":
      return RadioField;
    case "dropdown":
      return SelectField;
    case "label":
      return LabelField;
    case "richtext":
      return RichTextField;
    case "upload":
      return FileField;
    case "phonenumber":
      return PhoneField;
    case "colorpicker":
      return ColorPicker;
    case "date":
    case "datetime":
      return StyledDate;
    case "number":
      return NumberField;
    case "url":
      return UrlField;
    case "serverprop":
      return ServerPropField;
    case "apiprop":
      return ServerPropField;      
    case "location":
      return LocationField;
    case "checkbox":
      return CheckboxField;
    default:
      return InputField;
  }
}

let timeout = null;
let formikStatus = {};
let initialValues = {};
let defaultValues = {};
let validationSchema = {};

const parseNumbers = (value) => {
  const numbers = value.split(",").map(x => x.trim());
  const isValid = numbers.every(x => x !== "" && !isNaN(x) && isFinite(x));
  return isValid ? numbers.map(Number) : null; 
};

const ShowEntryValue = ({value}) => {

  if(typeof value == "object")
    return <span className="font-bold">{value?.label}</span>

  if(typeof value == "string" && parseNumbers(value)){
    return (
      <div className="flex gap-2 text-3xl fond-bold">
        {parseNumbers(value)?.map((number, index, arr) => (
          <span key={index} className="number text-gray-700">
            {number}{index < arr.length - 1 ? " - " : ""}
          </span>
        ))}
      </div>
    )
  }

  return  <span className="font-bold">{value}</span>
}

const getValidationSchema = ({ schema, formikStatus }) => {
    let validationSchema = {};
    for (let control of schema) {
      let { type, name } = control;

      if (/label/i.test(type)) {
        continue;
      }

      let validation = mapValidationSchema(control, formikStatus);

      if (validation) {
        validationSchema[name] = validation;
      }
    }
    return validationSchema;
  };

const AsyncFunction = (async function () {}).constructor;

const FormWithContext = ({
  props,
  schema,
}) => {
  const {
    values,
    handleChange,
    setFieldValue,
    setValues,
    setStatus,
    status,
    validateForm,
    setErrors,
    setTouched,
    submitForm,
  } = props;
  let computeEvents = {};

  const { context } = useContext(AppContext);

  for (let control of schema) {
    let {
      validation,
      name,
      compute,
      script_field,
      options,
      script,
      type,
      use_field,
      server_fields,
      api_fields,
      api_function
    } = control;

    console.log("what are the script fields", script_field, name);

    if (script_field) {
      computeEvents[name] = [];
      console.log("script field is active", computeEvents[name]);

      let func = (() => {
        options = options || [];
        let scriptOptions = [...options];
        let defaultScript = script;

        console.log(
          "script field is func",
          scriptOptions,
          options,
          defaultScript,
          name
        );

        return (e) => {
          console.log("script field is rendered here");

          let { value: fieldValue, name: fieldName } = e.target;
          let scriptProps =
            {}.constructor == fieldValue?.constructor
              ? fieldValue
              : scriptOptions.find((m) => m.value === fieldValue);

          if (defaultScript) {
            scriptProps = scriptProps || { script: defaultScript };
          }

          console.log("scriptProps", scriptProps);
          if (!scriptProps) return;

          let scriptArr = scriptProps.script;

          let newStatus = {};

          scriptArr.forEach((sc) => {
            let { field, ...rest } = sc;
            newStatus[field] = rest;
          });

          console.log("newStatus", newStatus);

          let statusMod = { ...(status || {}), ...newStatus };

          if (Object.keys(statusMod).length > 0) {
            formikStatus = { ...statusMod };
            setStatus(statusMod);
          }
        };
      })();

      computeEvents[name].onChange = (
        computeEvents[name]?.onChange ?? []
      ).concat(func);
      console.log(
        "what does compute events do",
        computeEvents,
        computeEvents[name],
        name,
        computeEvents[name].onChange
      );
    }

    console.log(
      "let see the serverprop server_fields",
      type,
      server_fields,
      name
    );

    var apiFields = {
      "serverprop":server_fields,
      "apiprop":api_fields
    }

    if(Object.keys(apiFields).includes(type) && apiFields[type]){
      const _fields = apiFields[type]
      for (let field of _fields) {
        let func = (() => {
          let fields = [..._fields];
          let serverControl = { ...control };

          console.log(
            "the serverprop fields and servercontrol fields",
            fields,
            serverControl,
            name
          );
          return (e) => {
            let { value: fieldValue, name: fieldName } = e.target;
            let currVals = {};

            console.log("what is serverprop e", name, e);

            fieldValue = moment.isDate(fieldValue)
              ? moment(fieldValue).format("YYYY-MM-DD")
              : fieldValue;

            currVals[fieldName] = fieldValue?.value ?? fieldValue;
            fields.forEach((f) => {
              if (f === fieldName) return;
              let value = values[f];
              value = moment.isDate(value)
                ? moment(value).format("YYYY-MM-DD")
                : value;
              currVals[f] = value?.value ?? value;
            });

            if (Object.values(currVals ?? {}).some((x) => !isValidValue(x))) {
              return;
            }

            console.log("the currvalues for serverprop", currVals, name);

            let handler = () => {
              setFieldValue(name, "loading...");
              let payload = {
                jsonString: JSON.stringify([currVals]),
                targetId: serverControl?.target_id?.toString(),
                target: serverControl?.target,
                applicationId: context?.menuId?.toString,
                parentDropValue: null,
                apiFunction: api_function
              };

              console.log("the payload for serverprop is", payload);
              console.log(
                "the application id for serverprop is",
                context?.menuId
              );

              const url = type == "serverprop" ? apiEndpoints.getServerProps : apiEndpoints.getApiProps
              appFetch
                .post(url, {
                  body: JSON.stringify(payload),
                })
                .then((res) => {
                  console.log("response for serverprop", res);
                  setFieldValue(name, res);
                })
                .catch((e) => console.log("error from serverprop", e.message))
                .finally();
            };

            //debounce here
            console.log("timeout", timeout);
            if (timeout) {
              clearTimeout(timeout);
            }
            timeout = setTimeout(handler, 500);
          };
        })();

        if (!computeEvents[field]) {
          computeEvents[field] = {};
        }

        computeEvents[field].onChange = (
          computeEvents[field]?.onChange ?? []
        ).concat(func);
      }
    }

    //for computer fields
    if (compute instanceof Object) {
      let { operator, withField, use_field } = compute;
      let reduceFunc = mapOperator(operator);

      for (let field of withField) {
        let func = (() => {
          let fields = [...withField];
          return (e) => {
            let { value: fieldValue, name: fieldName } = e.target;
            let currVals = {};
            currVals[fieldName] = fieldValue;

            let computeValues = fields.map((f) => {
              if (f === fieldName) {
                return parseFloat(fieldValue?.replace(/\,/g, ""));
              }
              return parseFloat(values[f]?.replace(/\,/g, ""));
            });

            let result = computeValues.reduce(reduceFunc);

            const lineDiscount = parseFloat(
              values["line_unit_discount"] || values["line_discount"]
            );
            const lineInterest = parseFloat(
              values["line_unit_interest"] || values["line_interest"]
            );

            if (lineDiscount) {
              result -= result * (lineDiscount * 0.01);
            }

            if (lineInterest) {
              result += result * (lineInterest * 0.01);
            }

            console.log("computeValues value", result);

            setFieldValue(name, formatNumber(result) || "");
          };
        })();

        if (!computeEvents[field]) {
          computeEvents[field] = {};
        }

        computeEvents[field].onChange = (
          computeEvents[field]?.onChange ?? []
        ).concat(func);
      }
      //delete control.compute;
      control.disabled = true;
    }

    if (Array.isArray(use_field)) {
      for (let field of use_field) {
        let func = (() => {
          let fields = [...use_field];
          return (e) => {
            let { value: fieldValue, name: fieldName } = e.target;
            let currVals = {};
            currVals[fieldName] = fieldValue;
            console.log("result is using field", use_field);

            let computeValues = fields.map((f) => {
              if (f === fieldName) {
                return fieldValue
                  ?.toLowerCase()
                  ?.replace(/[^a-zA-Z\-_ ]/g, "")
                  .trim()
                  .replace(/\s+/g, "-");
              }
              return values[f]
                ?.toLowerCase()
                ?.replace(/[^a-zA-Z\-_ ]/g, "")
                .trim()
                .replace(/\s+/g, "-");
            });

            let result = computeValues.join("");
            console.log("result is", result);

            setFieldValue(name, result);
          };
        })();

        if (!computeEvents[field]) {
          computeEvents[field] = {};
        }

        computeEvents[field].onChange = (
          computeEvents[field]?.onChange ?? []
        ).concat(func);
      }
    }
  }


  const getComputeEvent = (name) => {
    return computeEvents[name] || {};
  };

  return (
    <>
      {schema &&
        schema.map((o, idx) => {
          // let key = `${o.target_id}_${idx}`
          let key = `${o.target_id}`;
          let events = computeEvents[o.name] ?? {};

          console.log("the events", events, o.name);
          //eg: onChange: [] onKeyup: []
          let eventHandlers = {};
          Object.keys(events)?.length > 0 &&
            console.log("computeEvents2", {
              computeEvents,
              events: Object.keys(events),
            });
          Object.keys(events).forEach((eventType) => {
            //eventType onChange
            console.log(
              "the events ish for serverprops",
              eventType,
              events,
              o.name
            );

            eventHandlers[eventType] = (e) => {
              //e as target
              console.log(
                "the eventhandlers for serverprops",
                eventHandlers,
                eventType,
                events,
                o.name
              );

              handleChange(e);
              events[eventType].forEach((func) => {
                func(e);
              });
            };
          });

          console.log("eventHandlers", eventHandlers);

          let script = (status ?? {})[o.name];
          let visible = script?.visible ?? eval(o?.visible) ?? true;

          return (
            // <div className="mb-4 grid" key={key}>
            //   {React.createElement(mapField(o.type), {...o,...getComputeEvent(o.name)})}
            // </div>

            <>
              {visible && (
                <div className="mb-4 grid" key={key}>
                  {React.createElement(mapField(o.type), {
                    ...o,
                    ...eventHandlers,
                    status,
                  })}
                </div>
              )}
            </>
          );
        })}
    </>
  );
};

function FormConfirmDialog(props) {
  let { confirmDialog, setConfirmDialog } = props;
  const handleClick = confirmDialog.onConfirm
  const isAsyncHandleClick = handleClick instanceof AsyncFunction

  const {values} = confirmDialog
  console.log("valuesvalues",values)

  const schema = confirmDialog?.schema || []

  for (let control of schema) {
    let { type, value, name, defaultValue } = control;

    if (/label/i.test(type)) {
      continue;
    }

    let controlValue = defaults(type);

    initialValues[name] = typeof value == "undefined" ? controlValue : value;
    defaultValues[name] = controlValue;
  }

  return (
    <Dialog
      open={confirmDialog.isOpen}
      as="div"
      onClose={() => {}}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      <div className="min-h-screen h-full px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <span className="inline-block h-full align-middle" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden
              shadow-xl transform transition-all sm:my-0 sm:align-middle max-w-lg w-full sm:p-6 rounded-lg">
          <Dialog.Title>
            <h6 className="hover:cursot-default border-b text-lg font-semibold uppercase">Confirm Submission</h6>
          </Dialog.Title>
          <Dialog.Description className="items-center p-2">
            <p>{confirmDialog.title}</p>
            <p>{confirmDialog.subTitle}</p>
          </Dialog.Description>
          <Dialog.Panel className="flex flex-col gap-3">
            {(Object.entries(values ?? {}).map(([key, value]) => {
              return (
                <>
                  <div className="flex gap-3 items-center">
                    <ChevronDoubleRightIcon className="text-gray-200 w-6 h-6" />
                    <div className="flex flex-col">
                      <span className="text-md font-medium capitalize">
                        {key.replace(/\_/g, ' ')}
                      </span>
                      <ShowEntryValue value={value} />
                    </div>
                  </div>
                  <hr className="h-px mb-1 bg-gray-200 border-0 dark:bg-gray-500" />
                </>
              )
            }))}
            <div className="">
              <Formik
                //  key={confirmDialog.action}
                initialValues={initialValues}
                validationSchema={Yup.object(getValidationSchema({ schema, formikStatus }))}
                onSubmit={async (values, { setValues, resetForm, setStatus }) => { 
                  setConfirmDialog({ ...confirmDialog, isOpen: false })
                  handleClick(values)
                }}
              >
                {(props) => {
                  const {
                    values,
                    resetForm,
                    validateForm,
                    submitForm,
                    setValues,
                    setStatus,
                    setTouched,
                    isValid,
                  } = props;
                  return (
                    <>
                      <FormikForm>
                        <FormWithContext
                          props={props}
                          schema={schema}
                        />
                      </FormikForm>
                      <div className="space-x-3 flex justify-end">
                        <button
                          className="px-4 py-2 text-sm font-medium rounded-md text-black border-transparent bg-accent cursor-pointer border-b hover:shadow-md"
                          onClick={() =>
                            setConfirmDialog({ ...confirmDialog, isOpen: false })
                          }
                        >
                          Back
                        </button>
                        {isAsyncHandleClick
                          ? <button
                            className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium
                          text-white border-b bg-primary cursor-pointer hover:shadow-md"
                            onClick={e => {
                              submitForm();
                            }}>
                            Continue
                          </button>
                          : <button
                            className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium
                        text-white border-b bg-primary cursor-pointer hover:shadow-md"
                            onClick={async e => {
                              // setConfirmDialog({ ...confirmDialog, isOpen: false })
                              alert("sdfafsa")
                              // await handleClick()
                            }}>
                            Continue
                          </button>
                        }
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}

export default FormConfirmDialog;
