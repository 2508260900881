let base = "";
if (process.env.NODE_ENV === "production")
  base = process.env.REACT_APP_THEPLACE_VENDOR_SERVICE;
if (process.env.NODE_ENV === "development")
  base = process.env.REACT_APP_DEVELOPMENT_API;
if (process.env.REACT_APP_ENV === "staging")
  base = process.env.REACT_APP_STAGING_API;
if (process.env.REACT_APP_ENV === "development")
  base = process.env.REACT_APP_DEVELOPMENT_API;
if (process.env.REACT_APP_ENV === "practice")
  base = process.env.REACT_APP_PRACTICE_API;
if (process.env.REACT_APP_ENV === "local")
  base = process.env.REACT_APP_LOCAL_API;
if (process.env.REACT_APP_ENV === "loanapp")
  base = process.env.REACT_APP_LOAN_SERVICE;
if (process.env.REACT_APP_ENV === "theplace")
  base = process.env.REACT_APP_THEPLACE_API;
if (process.env.REACT_APP_ENV === "_master")
  base = process.env.REACT_APP_MASTER;
if (process.env.REACT_APP_ENV === "lssbi")
  base = process.env.REACT_APP_LSSBI_API;
if (process.env.REACT_APP_ENV === "ledger")
  base = process.env.REACT_APP_LEDGER_API;

const login = "user/login";
const logout = "user/logout";
const menuView = "app/menu-view";
const menuAction = "app/menu-action";
const dropdownView = "app/dropdown-view";
const fileUpload = "app/file-upload";
const uploadData = "app/upload-data";
const downloadData = "app/download-data";
const changePassword = "user/change-password";
const printAction = "app/print-action";
const forgotPassword = "user/forgot-password";
const sendOtp = "app/send-otp";
const getAppCache = "app";
const getServerProps = "app/get-server-props";
const walletBalance = "wallet/wallet-balance";
const fundWallet = "wallet/fund-wallet";
const createWallet = "wallet/create-wallet";
const withdrawWallet = "wallet/ withdraw-from-wallet";
const payBaba = "wallet/pay-baba";
const forgotWalletPin = "wallet/forgot-wallet-pin";
const ResetWalletPin = "wallet/reset-wallet-pin";
const getApiProps = "app/get-api-props";

const apiEndpoints = {
  login,
  logout,
  menuView,
  menuAction,
  dropdownView,
  fileUpload,
  changePassword,
  printAction,
  forgotPassword,
  sendOtp,
  getAppCache,
  uploadData,
  downloadData,
  getServerProps,
  getApiProps,
  walletBalance,
  fundWallet,
  createWallet,
  withdrawWallet,
  payBaba,
  forgotWalletPin,
  ResetWalletPin,
};

for (let endpoint in apiEndpoints) {
  apiEndpoints[endpoint] = `${base}/${apiEndpoints[endpoint]}`;
}

export default apiEndpoints;
