import React, { useRef, useState } from "react";
import classNames from "classnames";
import { PrintActiveIcon, ViewInactiveIcon } from "../LineIcons";
import Spinner from "../loaders/Spinner";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import {
  ArrowsExpandIcon,
  LockClosedIcon,
  PrinterIcon,
} from "@heroicons/react/solid";
import CloseIcon from "../icons/CloseIcon";
import { useTheme } from "styled-components";
import PrintPreview from "./PrintPreview";
import TransformValue from "./TransformValue";
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import { appendQueryToUri, getRandomValue } from "../util/generalUtil";
import ListPagination from "./ListPagination";
import Table, { SelectColumnFilter } from "./Table";
import moment from "moment";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DownloadIcon, XIcon } from "@heroicons/react/solid";
import PrintIcon from "../icons/PrintIcon";
import PrintButton from "./PrintButton";

const MySwal = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      cancelButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
    },
    buttonsStyling: false,
  })
);

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-80 bg-gray-100 rounded-sm mb-5 w-screen max-w-md">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-sm">No record available</h2>
    </div>
  );
};

const DataRow = ({ itemKeys, drillable, line }) => {
  const [drill, showDrill] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const showDetail = (item) => {
    console.log("the line is", item);
    setLoading(true);

    let postData = {
      layoutId: item?.layoutid,
      targetId: item?.targetid,
      target: item?.target,
      drillId: item.drillid?.toString(),
      targetName: item?.target_name,
      targetType: item?.target_type,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        let options = (res.options ?? [])[0] ?? null;
        showDrill(options);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        {itemKeys?.map((str, idx) => (
          <td class="px-4 py-4">
            <TransformValue value={line[str]} />
          </td>
        ))}
        {drillable && (
          <td class="px-4 py-4">
            <button
              className="inline-flex items-center justify-center w-7 h-7 rounded-md bg-background-yellow shadow hover:text-main-black"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (drill) {
                  showDrill(null);
                  return;
                }
                showDetail(line);
              }}
            >
              {drill ? (
                <CloseIcon fill={theme?.colors?.primary} width={10} />
              ) : (
                <ArrowsExpandIcon fill={theme?.colors?.primary} width={18} />
              )}
            </button>
          </td>
        )}
      </tr>
      {(loading || drill) && (
        <tr>
          <td colSpan={++itemKeys.length}>
            {loading && (
              <div className="grid place-items-center py-6">
                <Spinner fill="#e2e3e4" width={42} height={42} />
              </div>
            )}

            {drill?.gridtype === "horizontal" &&
              (() => {
                let drillKeys = Object.keys(
                  (drill?.grid ?? [])[0] ?? {}
                ).filter(
                  (head) =>
                    ![
                      "drillable",
                      "target_name",
                      "target_type",
                      "drillid",
                      "targetid",
                    ].includes(head)
                );

                return (
                  <div class="p-6 bg-gray-100 w-full">
                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            {drillKeys?.map((str, idx) => (
                              <th
                                scope="col"
                                class="px-4 py-3 text-xs"
                                key={idx}
                              >
                                {str}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {drill?.grid?.map((line, key) => {
                            return (
                              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                {drillKeys?.map((str, idx) => (
                                  <td class="px-4 py-4 text-xs">
                                    <TransformValue value={line[str]} />
                                  </td>
                                ))}
                              </tr>
                            );
                          })}
                          {drill?.grid?.length === 0 && (
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                              <td class="px-4 py-4 text-xs">
                                <span className="flex justify-center">
                                  No record available.
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })()}

            {drill?.gridtype === "vertical" &&
              (() => {
                const objKeys = Object.keys(
                  (drill?.grid ?? [])[0] ?? {}
                ).filter((key) => !excludeKeys.includes(key));
                return (
                  <div class="p-6 w-full">
                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                      <ul className="modal-list">
                        {drill?.grid.map((record) => (
                          <li className="modal-list-item list-none">
                            <ul className="grid gap-1 my-6 border-b border-gray-200 mb-3">
                              {objKeys.map((str, idx) => {
                                return (
                                  <li
                                    className={classNames(
                                      "flex justify-between"
                                    )}
                                    key={idx}
                                  >
                                    <div
                                      className={classNames("py-2 px-3 w-36", {
                                        "bg-gray-200": (idx + 1) % 2 != 0,
                                      })}
                                    >
                                      <span className="text-gray-700 font-semibold text-xs">
                                        {str}
                                      </span>
                                    </div>
                                    <div
                                      className={classNames(
                                        "ml-0.5 py-2 px-3 flex-grow",
                                        { "bg-gray-200": (idx + 1) % 2 != 0 }
                                      )}
                                    >
                                      <span className="text-xs">
                                        <TransformValue value={record[str]} />
                                      </span>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              })()}
          </td>
        </tr>
      )}
    </>
  );
};

const excludeKeys = [
  "drillable",
  "drillid",
  "target_name",
  "target_type",
  "targetid",
];

function ModalLayout1({
  options,
  targetid,
  layoutid,
  drillid,
  target,
  targetType,
  setLoading,
  handleCloseModal,
  dateFilter,
  accessFilter,
}) {
  options = (options ?? [])[0];
  const itemKeys = Object.keys((options?.grid ?? [])[0] ?? {}).filter(
    (key) => !excludeKeys.includes(key)
  );
  const drillable = (options?.grid ?? [])[0]?.drillable === "YES";
  const [valuee, setValue] = useState(options?.grid ?? []);
  const [dateRange, setDateRange] = useState(null);
  // let useTargetType = options?.length ? options[0]?.target_type : targetType;
  const searchRef = useRef(null);

  console.log("lets see the target type", targetType);

  const printPaper = () => {
    console.log("printing...");
  };

  console.log("the usetargettype", targetType);

  const exportData = () => {
    const searchText = searchRef.current.value;
    setLoading(true);
    let postData = {
      layoutId: layoutid,
      targetId: targetid,
      target: target,
      drillId: drillid,
      targetType: targetType,
      outputType: "DATASET",
      searchText,
      //   dateFilter: dateRange ? JSON.stringify(dateRange) : null,
      dateFilter: dateRange != null ? JSON.stringify(dateRange) : dateFilter,
      accessFilter: accessFilter ?? null,
    };

    let token = localStorage.getItem("token") ?? "";
    fetch(appendQueryToUri(apiEndpoints.downloadData, postData), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok)
          throw new Error("Something went wrong! could not download file.");
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${options?.Title?.replace(
          /\s+/,
          ""
        )?.toLowerCase()}-${moment().format("YYYY-MM-DD_h:msa")}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        MySwal.fire({
          text: e.message,
          icon: "error",
        });
      })
      .finally((e) => setLoading(false));
  };

  const filterByDateRange = (dateRange) => {
    setLoading(true);

    let postData = {
      layoutId: layoutid,
      targetId: targetid,
      target: "modal",
      dateFilter: dateRange != null ? JSON.stringify(dateRange) : dateFilter,
      accessFilter: accessFilter,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        setDateRange((prev) => dateRange);
        let options = (res.options ?? [])[0] ?? null;
        setValue(options?.grid ?? []);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const columns = itemKeys?.map((name) =>
    name == "Copde"
      ? {
          Header: name,
          accessor: name,
          Filter: SelectColumnFilter, // new
          filter: "includes",
        }
      : {
          Header: name,
          accessor: name,
        }
  );

  const printData = (options?.grid ?? [])[0];

  console.log("the gridType options are", options?.gridtype);

  console.log("modallayout dateFilter", dateFilter);
  console.log("the values are", valuee);
  return (
    <>
      <div className="flex justify-between items-center">
        <h3 className="font-semibold text-black-600">{options?.Title}</h3>
        <div className="flex justify-end gap-2">
          {options?.gridtype === "print" ? (
            <PrintButton data={printData} />
          ) : (
            <button
              onClick={exportData}
              className="bg-primary hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 rounded-md inline-flex items-center"
            >
              <DownloadIcon width={16} height={16} stoke="#ffffff" />
              <h3 className="uppercase ml-2 hidden lg:block">Export</h3>
            </button>
          )}
          <button
            onClick={handleCloseModal}
            className="bg-primary hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 rounded-md inline-flex items-center"
          >
            <XIcon fill="#ffffff" width={16} height={16} />
            <h3 className="uppercase ml-2 hidden lg:block">Close</h3>
          </button>
        </div>
      </div>
      <div className="mt-2">
        {options?.gridtype === "vertical" && (
          <ul className="modal-list">
            {options?.grid.map((record) => (
              <li className="modal-list-item list-none">
                <ul className="grid gap-1 my-6 border-b border-gray-200 mb-3">
                  {itemKeys?.map((str, idx) => {
                    return (
                      <li
                        className={classNames("flex justify-between")}
                        key={idx}
                      >
                        <div
                          className={classNames("py-2 px-3 w-36", {
                            "bg-gray-200": (idx + 1) % 2 != 0,
                          })}
                        >
                          <span className="text-gray-700 font-semibold text-xs">
                            {str}
                          </span>
                        </div>
                        <div
                          className={classNames("ml-0.5 py-2 px-3 flex-grow", {
                            "bg-gray-200": (idx + 1) % 2 != 0,
                          })}
                        >
                          <span className="text-xs">
                            <TransformValue value={record[str]} />
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </li>
            ))}
          </ul>
        )}

        {options?.gridtype === "horizontal" && (
          // <div class="relative overflow-x-auto shadow-md sm:rounded-lg scrollbar scrollbar-thumb-gray-600 scrollbar-track-gray-300">
          <div class="relative sm:rounded-lg">
            <Table
              searchRef={searchRef}
              columns={columns}
              data={valuee}
              filterByDateRange={filterByDateRange}
              drillable={drillable}
              dateFilter={dateFilter}
              accessFilter={accessFilter}
              noOfPages={options?.pages}
              records_per_page={options?.records_per_page}
              targetId={targetid}
              layoutId={layoutid}
            />

            {/* <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            {itemKeys?.map((str, idx) => (
                                <th scope="col" class="px-4 py-3" key={idx}>
                                    {str}
                                </th>
                            ))}
                            {drillable &&  
                            <th scope="col" class="px-4 py-3"> </th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {valuee?.map((line,key) => {
                            return (
                                <DataRow itemKeys={itemKeys} key={key} line={line} drillable={drillable} />
                            )
                        })}
                    </tbody>
                </table> */}
          </div>
        )}

        {options?.gridtype === "print" && (
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            {printData && <PrintPreview data={printData} />}
          </div>
        )}
        {/* <ListPagination
          records={records}
          itemsPerPage={options?.records_per_page}
          pageRangeDisplayed={3}
          changedRecords={(record) => setValue(record)}
          targetId={targetid}
          pages={options?.pages}
          setLoading={setLoading}
          layoutId={layoutid}
        ></ListPagination> */}
      </div>
    </>
  );
}

export default ModalLayout1;
