import React, { useContext, useEffect, useState } from "react";
import api from "../endpoints";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import FilterIcon from "../icons/FilterIcon";
import AppContext from "../contexts/AppContext";
// import EmptyRecord from './EmptyRecord';
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import { height } from "@material-ui/system";
import appFetch from "../appFetch";
import { appendQueryToUri } from "../util/generalUtil";
import apiEndpoints from "../apiEndpoints";
import WithLoader from "./WithLoader";
import DrillModalDialog from "./DrillModalDialog";
// import '../index.css';

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-96 bg-gray-100 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">No chart available</h2>
    </div>
  );
};

function ChartControl4({
  setLoading,
  name,
  options,
  filterOption,
  accessOption,
}) {
  const { context } = useContext(AppContext);
  const [modalData, setModalData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  // options = context?.chartControlOptions ?? options;
  console.log("the modalData is", modalData);

  const testing = {
    xAxis: {
      categories: [
        "01 - HEAD OFFICE",
        "03 - NBC CHALLAWA KANO",
        "02 - ILUBIRIN",
        "09 - KOKO BEACH",
        "36 - DANGOTE REFINERY",
        "46 - SHAGAMU CERAMIC FACTORY",
        "04 - NBC IKEJA",
        "05 - DIAMED",
        "IICC ILORIN",
        "07 - CASINO VI",
        "16 - PREMIER LOTTO ABUJA",
        "13 - ALAPERU",
        "49 - STANBIC TEMPORARY PROTECTIVE CANOPY",
        "112 - BATCHING PLANT ILUBIRIN",
        "113 - ONIKAN PROJECT",
        "48 - MEKUNWEN TOWN HOUSE",
        "117 - KARC LUTH",
        "18 - OODUA HOUSE",
        "114 - RCCG YABA",
        "15 - LADOJA IBADAN",
        "02 - PRESTIGE QUARY",
        "115 - TOWER BUSINESS SOLUTIONS",
        "QUARY- IBADAN",
      ],
    },
    targetid: 23893,
    yAxis: {
      title: {
        text: "Number of Employees",
      },
    },
    target: "modal",
    title: {
      text: "Employee By Location",
    },
    layoutid: "461",
    series: [
      {
        name: "01 - HEAD OFFICE",
        data: [42],
      },
      {
        name: "02 - ILUBIRIN",
        data: [46],
      },
      {
        name: "02 - PRESTIGE QUARY",
        data: [1],
      },
      {
        name: "03 - NBC CHALLAWA KANO",
        data: [18],
      },
      {
        name: "04 - NBC IKEJA",
        data: [9],
      },
      {
        name: "05 - DIAMED",
        data: [5],
      },
      {
        name: "07 - CASINO VI",
        data: [2],
      },
      {
        name: "09 - KOKO BEACH",
        data: [17],
      },
      {
        name: "112 - BATCHING PLANT ILUBIRIN",
        data: [7],
      },
      {
        name: "114 - RCCG YABA",
        data: [1],
      },
      {
        name: "117 - KARC LUTH",
        data: [3],
      },
      {
        name: "13 - ALAPERU",
        data: [2],
      },
      {
        name: "15 - LADOJA IBADAN",
        data: [1],
      },
      {
        name: "16 - PREMIER LOTTO ABUJA",
        data: [1],
      },
      {
        name: "18 - OODUA HOUSE",
        data: [1],
      },
      {
        name: "36 - DANGOTE REFINERY",
        data: [26],
      },
      {
        name: "46 - SHAGAMU CERAMIC FACTORY",
        data: [14],
      },
      {
        name: "48 - MEKUNWEN TOWN HOUSE",
        data: [1],
      },
      {
        name: "49 - STANBIC TEMPORARY PROTECTIVE CANOPY",
        data: [2],
      },
      {
        name: "IICC ILORIN",
        data: [14],
      },
      {
        name: "QUARY- IBADAN",
        data: [1],
      },
    ],
  };

  const graphData = (options ?? [])[0] ?? {};

  const graphData3 = {
    xAxis: {
      categories: [
        "01 - HEAD OFFICE",
        "03 - NBC CHALLAWA KANO",
        "02 - ILUBIRIN",
        "09 - KOKO BEACH",
        "36 - DANGOTE REFINERY",
        "46 - SHAGAMU CERAMIC FACTORY",
        "04 - NBC IKEJA",
        "05 - DIAMED",
        "IICC ILORIN",
        "07 - CASINO VI",
        "16 - PREMIER LOTTO ABUJA",
        "13 - ALAPERU",
        "49 - STANBIC TEMPORARY PROTECTIVE CANOPY",
        "112 - BATCHING PLANT ILUBIRIN",
        "48 - MEKUNWEN TOWN HOUSE",
        "117 - KARC LUTH",
        "18 - OODUA HOUSE",
        "114 - RCCG YABA",
        "15 - LADOJA IBADAN",
        "02 - PRESTIGE QUARY",
        "QUARY- IBADAN",
      ],
    },
    targetid: 23893,
    yAxis: {
      title: {
        text: "Number of Employees",
      },
    },
    target: "modal",
    title: {
      text: "Employee By Location",
    },
    layoutid: "461",
    series: [
      {
        data: [
          42, 46, 1, 18, 9, 5, 2, 17, 7, 1, 3, 2, 1, 1, 1, 26, 14, 12, 14, 1,
        ],
      },
    ],
  };

  const showDetail = (category) => {
    console.log("item", category);

    setLoading(true);
    let postData = {
      layoutId: graphData.layoutid,
      targetId: graphData.targetid,
      target: graphData.target,
      dateFilter: filterOption,
      accessFilter: accessOption,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        console.log("res", res);
        const { layoutid, targetid, drillid } = graphData;
        setModalData({ ...res, layoutid, targetid, drillid });
        setIsOpen(true);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  for (var i = 0; i < graphData?.series?.length; i++) {
    graphData.series[i] = { ...graphData.series[i] };
  }

  const graphOptions3 = {
    chart: {
      type: "bar",
      height: 550,
    },
    title: {
      text: "Monthly Average Rainfall",
    },
    xAxis: {
      categories: [],
    },
    yAxis: {},
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          inside: true,
          align: "right",
          style: {
            // fontFamily: 'Arial'
          },
          formatter: function () {
            return this.y.toLocaleString();
          },
          x: 20,
        },
        series: {
          point: {
            events: {
              click: function () {
                // Call the custom function with the category as an argument
                showDetail(this.category);
              },
            },
          },
        },
        showInLegend: false,
        borderRadius: 4,
        grouping: false,
        pointWidth: 20,
        groupPadding: 0.38,
        // pointPadding: 2,
        shadow: false,
      },
    },
    ...graphData,
  };

  console.log("chartControl4 graphOptions", graphOptions3, options);

  return (
    <>
      {isOpen && (
        <DrillModalDialog
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          item={modalData}
          setLoading={setLoading}
          dateFilter={filterOption}
          accessFilter={accessOption}
        />
      )}
      <div id="graph" className="mb-10">
        {Object.keys(graphData?.xAxis ?? {}).length > 0 ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={{ ...graphOptions3 }}
            containerProps={{ style: { width: "100%" } }}
          />
        ) : (
          <EmptyRecord />
        )}
      </div>
    </>
  );
}

export default WithLoader(ChartControl4);
