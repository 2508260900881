import React, {
  useContext,
  useState,
  Fragment,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { ThemeContext } from "styled-components";
import CategoryFillIcon from "../icons/CategoryFillIcon";
import { ArrowRightIcon, PlusIcon, XIcon } from "@heroicons/react/solid";

import PrintOutlineIcon from "../icons/PrintOutlineIcon";
import EditIcon from "../icons/EditIcon";
import DeleteIcon from "../icons/DeleteIcon";
import { Dialog, Transition, Menu } from "@headlessui/react";
import ReactJson from "react-json-view";
import classNames from "classnames";
import RightArrowIcon from "../icons/RightArrowIcon";
import WithLoader from "./WithLoader";
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import apiEndpoints from "../apiEndpoints";
import AppContext from "../contexts/AppContext";
import appFetch from "../appFetch";
import DrillModalDialog from "./DrillModalDialog";
import WalletLogo from "../assets/images/wemaBank.png";
import ZenithLogo from "../assets/images/zenith.png";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import RecentIcon from "../icons/RecentIcon";
import {
  ViewActiveIcon,
  ViewInactiveIcon,
  EditActiveIcon,
  EditInactiveIcon,
  DeleteActiveIcon,
  DeleteInactiveIcon,
} from "../LineIcons";
import ModalLayout1 from "./ModalLayout1";
import SearchControl1 from "./SearchControl1";
import ListPagination from "./ListPagination";
import ConfirmDialog from "./ConfirmDialog";
import { appendQueryToUri } from "../util/generalUtil";
import { isString } from "highcharts";
import { CheckmarkIcon } from "react-hot-toast";
import { useSubscriber } from "../hooks/useSubscriber";

const MySwal = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      cancelButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
    },
    buttonsStyling: false,
  })
);

const iconMap = {
  wema: WalletLogo,
  zenith: ZenithLogo,
};

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-80 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">No record available</h2>
    </div>
  );
};

const BadgeValue = (value) => {
  let color;
  if (value == "PENDING" || value == "Pending") {
    color = "bg-yellow-500";
  } else if (value == "APPROVED" || value == "Approved") {
    color = "bg-green-700";
  } else if (value == "REJECTED" || value == "Rejected") {
    color = "bg-red-700 ";
  } else if (value == "OPEN" || value == "Open") {
    color = " bg-emerald-300  ";
  } else if (value == "CLOSED" || value == "Closed") {
    color = "bg-blue-600";
  } else if (value == "Active" || value == "ACTIVE") {
    color = "bg-blue-600";
  } else {
    color = "bg-gray-600 ";
  }
  // let style = `capitalize text-xs text-white block ${color} rounded-md p-1 my-1 text-center max-w-min truncate`;
  let style = `rounded-tr-lg rounded-bl-lg w-full justify-end relative -top-6 -right-10 max-w-min my-1 capitalize text-xs ${color} px-2 py-1 origin-center -translate-y-1 text-white truncate`;

  return (
    <span className={style} dangerouslySetInnerHTML={{ __html: value }}></span>
  );
};

const PreviewRecord = (item) => {
  let { activeRecord: record, isOpen, setIsOpen } = item;
  let recordProps = Object.keys(record).filter(
    (str) => !["details", "edit"].includes(str)
  );
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  return (
    <>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
        open={isOpen}
      >
        <div className="min-h-screen h-full px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <span className="inline-block h-full align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Detail
            </Dialog.Title>
            <div className="mt-2">
              <ul className="grid gap-1 my-6">
                {recordProps
                  .filter((s) => !["details", "ID"].includes(s))
                  .map((str, idx) => {
                    return (
                      <li
                        className={classNames("flex justify-between")}
                        key={idx}
                      >
                        <div
                          className={classNames("py-2 px-3 w-36", {
                            "bg-gray-200": (idx + 1) % 2 != 0,
                          })}
                        >
                          <span className="text-gray-700 font-semibold text-sm">
                            {str}
                          </span>
                        </div>
                        <div
                          className={classNames("ml-0.5 py-2 px-3 flex-grow", {
                            "bg-gray-200": (idx + 1) % 2 != 0,
                          })}
                        >
                          <span className="text-sm">{record[str]}</span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="mt-4">
              <button
                type="button"
                className="pointer inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded-md"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

function SavedRecordList2({
  options,
  editRecord,
  target_id,
  setLoading,
  updateRecord,
  layoutid,
  edit_action,
  isOpen: mainModal,
  setInProp,
  closeModal,
  updateFormState,
}) {
  console.log("layout id ====>", layoutid);
  const theme = useContext(ThemeContext);
  // const [records,setRecords] = useState(options)
  console.log("record list", options);
  const [showPreview, setShowPreview] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const [dataOptions, setDataOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { context } = useContext(AppContext);
  const [search, setSearch] = useState(null);
  const reInit = () => setSearch(null);
  const [valuee, setValue] = useState((options ?? [])[0]?.grid);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const [activePage, setActivePage] = useState(0); // Track the active page (0-based index)
  const [activeWallet, setActiveWallet] = useState((options ?? [])[0]?.grid[0]);
  let optionsData = (dataOptions ?? [])[0];
  const { subs } = useSubscriber();

  console.log("the option types", valuee, options, optionsData);
  let active = options[0];

  const buildEditData = (postData, editData) => {
    let ref = postData;
    let count = 1;
    let curr = `ID_ROOT_${count}`;
    let prop = "";
    while (true) {
      prop = editData[`ID_ROOT_${count}`];

      //check if next exist
      if (typeof editData[`ID_ROOT_${count + 1}`] == "undefined") break;

      ref[prop] = typeof ref[prop] != "undefined" ? ref[prop] : {};
      ref = ref[prop];

      count++;
    }

    ref[prop] = editData[prop];
    return postData;
  };

  const UpdateData = async (action, item) => {
    return new Promise(async (resolve, reject) => {
      let test = [{ _SETUP: { ID: item?.edit[0]?.ID } }];
      let builtData = await buildEditData(test, item?.edit[0]);

      console.log("does buildata work here", builtData);

      setLoading(true);

      let postData = {
        jsonString: JSON.stringify(builtData),
        menuId: context?.menuId?.toString(),
        pageButtonActionId: JSON.stringify(target_id),
        buttonAction: action ?? item?.target_type,
      };

      console.log("preview postData formLine", postData, item);
      // return;

      appFetch
        .post(`${apiEndpoints.menuAction}?subs=${subs}`, {
          body: JSON.stringify(postData),
        })
        .then((res) => {
          let { ERROR_MESSAGE: errMsg, ERROR_NUMBER: errNo } = res;
          console.log("what is the response", res);

          resolve(res);
        })
        .catch((err) => {
          // toast.error(err.message ?? "an error occured!");
        })
        .finally(() => setLoading(false));
      // return result;
    });
  };

  const UpdateDataAsync = async (action, item) => {
    switchWallet(item);
    let data = await UpdateData(action, item);

    if (data.constructor == {}.constructor) {
      let savedRecords =
        data?.layout?.subcomponents?.find(
          ({ type }) => type === "SavedRecordList2"
        ) ??
        data?.layout?.subcomponents?.find(
          ({ type }) => type === "SavedTransactionList2"
        );
      updateRecord(savedRecords);
    }
  };

  const switchWallet = (wallet) => {
    // setLoading(true);
    setActiveWallet?.(wallet);
    updateFormState?.();
    setInProp?.(false);
    closeModal?.();
  };

  return (
    <div>
      {valuee &&
        valuee.map((wallet, idx) => {
          let active = wallet?.Status || "";
          console.log("compare wallet", wallet, activeWallet);

          let menuIcon = iconMap[wallet["Caption 1"].toLowerCase()];
          return (
            <div
              onClick={() => {
                // switchWallet(wallet);
                UpdateDataAsync("UPDATE", wallet);
              }}
              className="flex flex-row items-center space-x-7 p-5 cursor-pointer hover:bg-gray-50 transform transition-transform hover:translate-x-1"
            >
              {/* image */}
              <div className="py-5 px-3 bg-gray-100 rounded-xl">
                <img src={menuIcon} className="h-7 w-12 object-contain" />
              </div>

              {/* wallet name and information */}
              <div className="flex-1">
                <div className="uppercase font-semibold md:text-lg text-md">
                  {wallet["Caption 1"]}
                </div>
                <div className="md:text-lg text-sm">{wallet["Caption 2"]}</div>
              </div>

              {/* active tick  */}
              {active.toLowerCase() === "yes" && (
                <div>
                  <CheckmarkIcon />
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}

export default SavedRecordList2;
