import React, { useContext } from "react";
import { Dialog } from "@headlessui/react";
import renderComponent from "../renderComponent";
import ModalLayout1 from "./ModalLayout1";
import { appendQueryToUri } from "../util/generalUtil";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import apiEndpoints from "../apiEndpoints";
import { DownloadIcon, PlusIcon, XIcon } from "@heroicons/react/solid";
import WithLoader from "./WithLoader";
import WalletLogo from "../assets/images/wemaBank.png";
import { CheckmarkIcon } from "react-hot-toast";
import { ThemeContext } from "styled-components";

const MySwal = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      cancelButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
    },
    buttonsStyling: false,
  })
);

function AddWalletModal({
  isOpen,
  item,
  closeModal,
  setLoading,
  activeWallet,
  setActiveWallet,
}) {
  const theme = useContext(ThemeContext);

  console.log("item from drillmodal", item);

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={() => {}}
      open={isOpen}
    >
      <div className="min-h-screen h-full px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className=" flex justify-center items-center">
          <div className="inline-block w-full xl:max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-2xl">
            <div className="flex justify-end gap-2">
              <button
                onClick={closeModal}
                className="bg-primary hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 rounded-md inline-flex items-center"
              >
                <XIcon fill="#ffffff" width={16} height={16} />
                <h3 className="uppercase ml-2 hidden lg:block">Close</h3>
              </button>
            </div>

            <div>
              <div className="font-semibold md:text-xl text-base">
                Set Active Wallet
              </div>

              {item.map((wallet, idx) => {
                let active = wallet.name === activeWallet.name ? true : false;
                return (
                  <div
                    onClick={() => {}}
                    className="flex flex-row items-center space-x-7 p-5 cursor-pointer hover:bg-gray-50 transform transition-transform hover:translate-x-1"
                  >
                    {/* image */}
                    <div className="py-5 px-3 bg-purple-100 rounded-xl">
                      <img src={WalletLogo} className="h-7 object-contain" />
                    </div>

                    {/* wallet name and information */}
                    <div className="flex-1">
                      <div className="uppercase font-semibold md:text-lg text-md">
                        {wallet.name}
                      </div>
                      <div className="md:text-lg text-sm">
                        {wallet.account_number}
                      </div>
                    </div>

                    {/* active tick  */}
                    {active && (
                      <div>
                        <CheckmarkIcon />
                      </div>
                    )}
                  </div>
                );
              })}

              <div
                onClick={() => {}}
                className="flex flex-row items-center space-x-7 p-5 cursor-pointer hover:bg-gray-50 transform transition-transform"
              >
                {/* image */}
                <div
                  style={{ backgroundColor: theme?.colors.accent }}
                  className="py-4 px-4 rounded-xl"
                >
                  {/* <img src={WalletLogo} className="h-7 object-contain" /> */}
                  <PlusIcon height={40} color={theme?.colors.primary} />
                </div>

                {/* wallet name and information */}
                <div className="flex-1">
                  <div className="capitalize font-semibold md:text-lg text-md">
                    Add Wallet
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default WithLoader(AddWalletModal);
