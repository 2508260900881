import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import AppContext from "../contexts/AppContext";
import Select from "react-select";
import WithLoader from "./WithLoader";
import apiEndpoints from "../apiEndpoints";
import appFetch from "../appFetch";
import { appendQueryToUri } from "../util/generalUtil";
import uuid from "react-uuid";

const MapOption = ({ innerProps, data, children, label }) => {
  return (
    <div className="custom-select-option" {...innerProps}>
      {children}
    </div>
  );
};

const MapOptionValue = ({ children, data }) => {
  return <div className="custom-select-option-value">{children}</div>;
};

// const CountryValue = ({children, data}) => {
//     return (
//         <div className="country-value">
//             <ReactCountryFlag
//                     countryCode={data.CountryCode}
//                     className="country-flag"
//                     svg
//                     style={{
//                         width: '2em',
//                         height: '2em',
//                     }}
//                     title={data.Country}
//                 />
//             {children}
//         </div>
//     );
// }

const FilterItem = styled.div`
  background: ${({ theme }) => theme.colors.primary};
`;

const TagFilterItem = ({ active, Mapname, targetid, onClick }) => (
  <FilterItem
    className={classNames("tag filter", { active: active })}
    onClick={onClick}
    data-filter={targetid}
  >
    {Mapname}
  </FilterItem>
);

const VerticalLine = styled.div`
  font-size: 24px;
  color: #b0b0b0;
`;

function MapFilterControl1({ options, name, setLoading, selectedMapControl,setSelectedMapControl}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const { context, setContext } = useContext(AppContext);
  const [defaultVal, setDefaultVal] = useState(null);

  console.log("Map filter control",{selectedMapControl,setSelectedMapControl})

  const filterOption =
    options?.map(({ name, id }) => ({
      value: name,
      label: name,
      id: id,
    })) || [];

  //get options from context
  options = context?.MapFilterOptions ?? options;

  const activeTender = options?.find(({ name: itemName }) => itemName == name);

  console.log("default value", defaultVal);

  const handleChange = (selectedOption) => {
    const item = (options.find(({ name }) => selectedOption.label === name)
      ?.options ?? [])[0];
    console.log("selectedOption", item, options);

    setLoading(true);
    let postData = {
      layoutId: item?.layoutid,
      targetId: item?.targetid,
      target: item?.target
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        console.log("res", res);
        setSelectedMapControl(res)
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="border-bottom flex flex-col md:flex-row items-center rounded-md pb-2 -mt-4 justify-between">
        <h1 className="font-bold">{name}</h1>
        <div className="filter-group flex items-center flex-1 justify-end">
          <div className="tags">
            {/* <div>Filter by:</div> */}
            {activeTender?.options?.map((item, index) => {
              const active = index == activeIndex ? "active" : "";
              return (
                <TagFilterItem
                  key={index}
                  {...item}
                  active={active}
                  onClick={() => setActiveIndex(index)}
                />
              );
            })}
          </div>
          <div className="">
            <Select
              classNamePrefix="custom-select-option"
              components={{
                Option: MapOption,
                SingleValue: MapOptionValue,
              }}
              key={filterOption[0]?.id}
              defaultValue={filterOption[0]}
              isClearable={false}
              onChange={handleChange}
              options={filterOption}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default WithLoader(MapFilterControl1);
