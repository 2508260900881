import React, { Component, useMemo, Fragment } from "react";
import Stepper from "react-stepper-horizontal";
import { isSchema, object } from "yup";
import Form1 from "./Form1";
import component from ".";
import CartBagIcon from "../icons/CartBagIcon";
import SidebarModal from "./SidebarModal";
import { ScreenLoader } from "./ScreenLoader";
import { ScreenOverlay } from "./ScreenLoader";
import { ThemeContext } from "styled-components";
import PreviewTransaction from "./PreviewTransaction";
import uuid from "react-uuid";
import ReactJson from "react-json-view";
import WithLoader from "./WithLoader";
import cart from "../cartItems.json";
import CloseIcon from "../icons/CloseIcon";
import mapInitialValues from "../mapInitialValues";
import styled from "styled-components";
import ImagePlaceholder from "../assets/images/image-placeholder.svg";
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import { formatNumber } from "../util/formatter";
import ListCartSource from "./ListCartSource";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { Dialog, Transition, Menu } from "@headlessui/react";
import {
  EditActiveIcon,
  EditInactiveIcon,
  DeleteActiveIcon,
  DeleteInactiveIcon,
} from "../LineIcons";

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-80 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">cart is empty</h2>
    </div>
  );
};

const ControlButton = styled.button`
  background: #fdf9eb;
  border-radius: 5px 0px 0px 5px;
`;

const cartLines = [
  {
    cart_line_1: "Bread",
    cart_line_2: "kg",
    cart_quantity: "1000",
    cart_line_3: "",
    cart_line_4: "No",
  },
  {
    cart_line_1: "Baking powder",
    cart_line_2: "kg",
    cart_quantity: "2000",
    cart_line_3: "",
    cart_line_4: "No",
  },
];

const getValue = (value) => value?.split("=>")[0] || "";

const CartItems = ({ items, deleteItemFromCart, editCartItem }) => {
  // items = cartLines
  return (
    <div>
      <h2 className="uppercase font-semibold text-md">Cart</h2>
      {(items?.length || 0) == 0 && <EmptyRecord />}
      <ul className="grid gap-4 mt-10">
        {items?.map((item, idx) => (
          <li className="border rounded-md px-4 py-4 relative bg-gray-100 bg-opacity-40">
            <div className="flex justify-between items-center pr-2">
              <div className="flex items-center">
                <div className="bg-gray-100 shadow px-2 justify-center rounded-md inline-flex items-center mr-3 w-8 h-6">
                  <span className="text-md font-bold">{idx + 1}</span>
                </div>

                <div>
                  {item?.cart_line_1 && (
                    <div className="text-gray-700 text-sm truncate">
                      <span className="font-medium text-black">
                        {getValue(item?.cart_line_1)}
                      </span>
                    </div>
                  )}
                  {item?.cart_line_2 && (
                    <div className="text-gray-700 text-sm truncate">
                      <span className="mr-1 capitalize text-xs">
                        {getValue(item?.cart_line_2)}
                      </span>
                    </div>
                  )}
                  {item?.cart_line_3 && (
                    <div className="text-gray-700 text-sm truncate">
                      <span className="mr-1 capitalize text-xs">
                        {getValue(item?.cart_line_3)}
                      </span>
                    </div>
                  )}
                  {item?.cart_line_4 && (
                    <div className="text-gray-700 text-sm truncate">
                      <span className="mr-1 capitalize text-xs">
                        {getValue(item?.cart_line_4)}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-start flex-col mr-4">
                {item?.cart_quantity && (
                  <div className="flex">
                    <span
                      className="font-medium text-black text-center text-black-400 border text-sm rounded-sm h-6 px-2 inline-flex items-center"
                      style={{ borderColor: "#9A9FAD", background: "#F4F4F4" }}
                    >
                      {item?.cart_quantity}{" "}
                    </span>
                  </div>
                )}
                {item?.cart_unit_amount && (
                  <div className="text-gray-700 text-sm truncate font-semibold">
                    <span className="mr-1 capitalize text-xs">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item?.cart_currency,
                        }}
                      ></span>
                      {formatNumber(item.cart_unit_amount)}
                    </span>
                  </div>
                )}
                {item?.cart_discount && (
                  <div className="text-gray-700 text-sm truncate font-semibold">
                    <span className="mr-1 capitalize text-xs">
                      {formatNumber(item.cart_discount)}%
                    </span>
                  </div>
                )}
                {item?.cart_total_amount && (
                  <div className="text-gray-700 text-sm truncate font-semibold">
                    <span className="mr-1 capitalize text-xs">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item?.cart_currency,
                        }}
                      ></span>
                      {formatNumber(item.cart_total_amount)}
                    </span>
                  </div>
                )}
              </div>
              {/* <CloseIcon width={12} height={12} fill="#747474" className="ml-2 absolute top-1/2 transform -translate-y-1/2 right-4" onClick={() => {
                  deleteItemFromCart(idx);
              }} /> */}
              <Menu
                as="div"
                className="text-left ml-2 absolute top-1/2 transform -translate-y-1/2 right-4 z-10"
              >
                <div>
                  <Menu.Button className="inline-flex justify-center w-full px-0.5 py-1 text-sm font-medium text-custom-gray-200 rounded-sm">
                    <DotsVerticalIcon
                      width={12}
                      height={12}
                      className="w-5 h-5 text-violet-200 hover:text-violet-100"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-10 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      {/* <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => editCartItem(idx)}
                              className={`${
                                active
                                  ? "bg-primary text-white"
                                  : "text-gray-900"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              {active ? (
                                <EditActiveIcon
                                  className="w-5 h-5 mr-2"
                                  aria-hidden="true"
                                />
                              ) : (
                                <EditInactiveIcon
                                  className="w-5 h-5 mr-2"
                                  aria-hidden="true"
                                />
                              )}
                              Edit
                            </button>
                          )}
                        </Menu.Item> */}
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => deleteItemFromCart(idx)}
                            className={`${
                              active ? "bg-primary text-white" : "text-gray-900"
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          >
                            {active ? (
                              <DeleteActiveIcon
                                className="w-5 h-5 mr-2 text-violet-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <DeleteInactiveIcon
                                className="w-5 h-5 mr-2 text-violet-400"
                                aria-hidden="true"
                              />
                            )}
                            Delete
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

class FormStepper1 extends Component {
  static contextType = ThemeContext;
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    // console.log("props",props);
    this.nextPage = this.nextPage.bind(this);
    this.setFormData = this.setFormData.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.addItemToCart = this.addItemToCart.bind(this);
    this.clearCart = this.clearCart.bind(this);
    this.deleteItemFromCart = this.deleteItemFromCart.bind(this);
    this.editCartItem = this.editCartItem.bind(this);
    this.openCart = this.openCart.bind(this);
    this.toggleCartSidebar = this.toggleCartSidebar.bind(this);
    this.setProcessing = this.setProcessing.bind(this);
    this.updatePreviewData = this.updatePreviewData.bind(this);
    this.setIsOpen = this.setIsOpen.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setNewInitialValues = this.setNewInitialValues.bind(this);

    console.log("stepper", props.children);

    //formstepper is a pure object
    this.formStepper = props?.schema;
    this.formDefaults = mapInitialValues(this.formStepper);
    this.formInitialValues = this.formDefaults.formInitialValues;
    this.formDefaultValues = this.formDefaults.formDefaultValues;

    // formStepper: props?.schema?.slice(3),

    this.state = {
      page: 0,
      formData: null,
      formStepper: props?.schema,
      cart: [],
      showCartSidebar: false,
      isProcessing: false,
      previewData: null,
      isOpen: false,
      activeCart: null,
      formStepperName: props?.name,
    };

    this.state = {
      ...this.state,
      formInitialValues: this.formDefaults.formInitialValues, // Move to state
    };
  }

  handlePrint() {
    console.log("printing");
  }

  postData(values) {
    let postData = {
      jsonString: JSON.stringify([values]),
      sessionId: "E315E746-3DCC-45EF-82DE-02138152473D",
      applicationId: "601",
      menuId: "702",
      pageId: "6001",
      buttonAction: "SAVE",
    };

    console.log("postData", postData);

    let response = fetch("https://localhost:7230/App/action", {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => console.log("response from server", result));
  }

  handleSubmit() {
    let jsonData = { ...this.state.formData };
    this.postData(jsonData);
  }

  setIsOpen(state) {
    console.log("setting open state", state);
    this.setState((prev) => ({ ...prev, isOpen: state }));
  }

  setNewInitialValues(values) {
    console.log("setting new initial values", values);
    this.setState({ formInitialValues: values });
  }

  toggleCartSidebar(status) {
    this.setState((prev) => ({ ...prev, showCartSidebar: status }));
  }

  setProcessing(status) {
    this.setState((prev) => ({ ...prev, isProcessing: status }));
  }

  setFormData(newFormData) {
    console.log("preparing prev");
    // let formData = {...newFormData}
    this.setState((prev) => ({
      ...prev,
      formData: { ...prev.formData, ...newFormData },
    }));
    console.log("formData", this.state.formData);
  }

  editCartItem(idx) {
    let cartItem = this.state.cart[idx];

    let cartLineNames = Object.keys(cartItem);
    let editLine = {};

    let step = this.state.formStepper[this.state.page];
    let formControls = step.options;

    for (let control of formControls) {
      let { name, cart, type } = control;
      if (cartLineNames.includes(cart)) {
        let cartValue = cartItem[cart]?.toString();
        if (type == "number") {
          cartValue = formatNumber(cartValue);
          console.log("control => ", control, {
            control,
            result: type == "number",
            cartValue,
          });
        }

        editLine[name] = cartValue?.split("=>").slice(-1)[0] || "";
      }
    }

    console.log("editLine", editLine);

    this.setState((prev) => ({
      ...prev,
      activeCart: editLine,
      showCartSidebar: false,
    }));
  }

  addItemToCart({ item, lineValues, preview, cartRoot }) {
    console.log("adding...", { item, lineValues, preview });
    let myCart = this.state.cart;
    let previewData = this.state.previewData;
    let oldLines = (previewData ?? {})[preview] ?? [];

    let { cart_total_amount, cart_quantity, ...cartJson } = item;
    let cartString = JSON.stringify(cartJson);
    console.log("cartString", cartString);

    //if edit mode
    let activeCart = this.state.activeCart;

    let cartIdx = null;
    let cartItem = myCart.find((m, idx) => {
      let { cart_total_amount, cart_quantity, ...rowJson } = m;
      let match = JSON.stringify(rowJson) == cartString;
      if (match) {
        cartIdx = idx;
      }
      return match;
    });

    if (cartItem) {
      //update quantity and total amount
      // cartItem.cart_quantity += item.cart_quantity;
      console.log("typeof item.cart_quantity", typeof item.cart_quantity);
      console.log(
        "typeof cartItem.cart_quantity",
        typeof cartItem.cart_quantity
      );
      cartItem.cart_quantity =
        parseFloat(item.cart_quantity) +
        parseFloat(activeCart ? 0 : cartItem.cart_quantity);

      if (cartItem.cart_total_amount) {
        cartItem.cart_total_amount = parseFloat(
          cartItem.cart_quantity * parseFloat(cartItem.cart_unit_amount)
        );

        if (cartItem.cart_discount) {
          cartItem.cart_total_amount -=
            cartItem.cart_total_amount *
            (parseFloat(cartItem.cart_discount) * 0.01);
        }
      }
    }

    let lineItem = oldLines?.find((m, idx) => idx == cartIdx);
    if (lineItem) {
      if (lineItem.line_quantity)
        lineItem.line_quantity =
          lineValues.line_quantity + (activeCart ? 0 : lineItem.line_quantity);
      if (lineItem.line_months)
        lineItem.line_months =
          lineValues.line_months + (activeCart ? 0 : lineItem.line_months);

      let qty = lineItem.line_quantity ?? lineItem.line_months ?? 1;

      if (lineItem.line_total_amount) {
        lineItem.line_total_amount = parseFloat(
          qty * lineItem.line_unit_amount
        );

        if (lineItem.line_discount) {
          lineItem.line_total_amount -=
            lineItem.line_total_amount * (lineItem.line_discount * 0.01);
        }
      }
    }

    let newCart = cartItem
      ? [...myCart.filter((m, i) => i != cartIdx), cartItem]
      : [...myCart, item];
    let lines = cartItem
      ? [...oldLines.filter((m, i) => i != cartIdx), lineItem]
      : [...oldLines, lineValues];

    if (previewData && preview) {
      previewData[preview] = lines;
    }
    console.log("previewData", previewData);

    this.setState((prev) => ({
      ...prev,
      cart: newCart,
      previewData,
      cartRoot,
      previewRoot: preview,
      activeCart: null,
    }));
  }

  clearCart() {
    let cart = [];
    this.setState((prev) => ({ ...prev, cart }));
  }

  deleteItemFromCart(idx) {
    let cartRoot = this.state.cartRoot;
    let previewRoot = this.state.previewRoot;

    let previewData = this.state.previewData;
    let formData = this.state.formData;

    let cart = this.state.cart?.filter((i, index) => index !== idx);
    let formDataLines = formData[cartRoot]?.filter((i, index) => index !== idx);

    if (previewRoot && previewData) {
      let lines = previewData[previewRoot]?.filter((i, index) => index !== idx);
      previewData.line = lines;
    }

    formData[cartRoot] = formDataLines;

    this.setState((prev) => ({ ...prev, cart, previewData, formData }));
  }

  nextPage(data) {
    this.setState((prev) => ({
      ...prev,
      formData: { ...prev.formData, ...data },
    }));
    let currentPage = this.state.page + 1;
    if (!this.state.formStepper[currentPage]) {
      return;
    }
    // console.log(this.formStepper[currentPage].options);
    // let rules = this.formStepper[currentPage].options

    // let carts = this.props.carts;

    // var rulesMap = {}
    // for(var i=0; i<rules.length; i++){
    //   if(rules[i].type == "label") continue;

    //   rulesMap[rules[i].name.toLowerCase()] = rules[i]
    // }

    // let cartItems
    // cartItems = carts?.map(x => {
    //   const cartItem = {};
    //   Object.entries(x).forEach(xx => {
    //     let [key, value] = xx
    //     key = key.toLowerCase();
    //     let control = rulesMap[key];
    //     if(!control) return;

    //     if(control.type == "select"){
    //         cartItem[control.cart] = `noName=>${value}`
    //         return;
    //     }
    //     let prop1;
    //     if(({}).constructor == value.constructor){
    //         prop1 = Object.keys(value)[0]
    //         cartItem[control.cart] = value[prop1];
    //         return;
    //     }
    //     cartItem[control.cart] = value[prop1];
    //   })
    //   cartItems?.push(cartItem);
    //   return cartItem
    // })

    // if(this.props.edit){
    //   this.setState(prev => ({cart:cartItems}))
    //   // console.log(carts,cartItems)
    // }

    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  updatePreviewData(data) {
    let oldData = this.state.previewData;
    let previewData = { ...oldData, ...data };
    this.setState((prev) => ({ ...prev, previewData }));
  }

  // updatePreviewData({key,values,useCart}) {
  //   console.log("preview data", {key,values,useCart})
  //   let newValues = {...values};
  //   let previewData = {...this.state.previewData}

  //   for (let prop in newValues){
  //     let value = newValues[prop];

  //     if(({}).constructor === value?.constructor){
  //       let {label, ...others} = value;
  //       newValues[prop] = {...others};
  //     }

  //     if(Array.isArray(value)){
  //       newValues[prop] = value.map(v => {
  //         if(({}).constructor === v?.constructor){
  //           let {label,...rest} = v;
  //           return {...rest}
  //         }
  //         return v;
  //       })
  //     }
  //   }

  //   if(previewData[key] && useCart){
  //       previewData[key] = [...previewData[key]].concat(newValues);
  //   }else if(previewData[key]){
  //     previewData[key] = {...previewData[key], ...newValues}
  //   }else if (useCart){
  //     previewData[key] = [newValues]
  //   }else{
  //     previewData[key] = newValues
  //   }

  //   console.log("previewData",previewData)
  //   this.setState(prev => ({...prev, previewData}));
  // }

  openCart() {
    console.log("opening cart");
  }

  render() {
    const { onSubmit } = this.props;
    const { page, formStepper } = this.state;
    const steps = formStepper?.map(({ name }) => ({ title: name }));
    const theme = this.context;
    // const [inProp,setInProp] = useState(false);
    console.log("forminitialvalues 2 => ", this.state.formInitialValues);

    const openCartSidebar = () => {
      // this.setProcessing(true);
      this.props.setLoading(true);

      setTimeout(() => {
        // this.setProcessing(false);
        this.props.setLoading(false);
        this.toggleCartSidebar(true);
      }, 600);
    };

    const closeSidebar = () => {
      setTimeout(() => {
        this.toggleCartSidebar(false);
      }, 400);
    };

    console.log("formStepper", formStepper);

    // console.log("schema is changing",{schema:formStepper[page]});

    const currentStep = formStepper[page];
    const previousStep = formStepper[page - 1];
    console.log("currentStep", currentStep);
    console.log("currentStep previousStep", previousStep);

    if (!formStepper) return <></>;

    return (
      <>
        {this.state.isOpen && (
          <PreviewTransaction
            {...{
              name: this.state.formStepperName,
              data: this.state.previewData,
              isOpen: this.state.isOpen,
              setIsOpen: this.setIsOpen,
              handleSubmit: this.handleSubmit,
              handlePrint: this.handlePrint,
            }}
          />
        )}

        {/* {this.state.showCartSidebar &&
         <ScreenOverlay>
          <SidebarModal {...{inProp:true,closeSidebar}}><CartItems items={this.state.cart} /></SidebarModal></ScreenOverlay>}
          {this.state.isProcessing && <ScreenLoader />
        } */}

        {this.state.showCartSidebar && (
          <SidebarModal
            inProp={this.state.showCartSidebar}
            closeSidebar={closeSidebar}
          >
            <CartItems
              items={this.state.cart}
              deleteItemFromCart={this.deleteItemFromCart}
              editCartItem={this.editCartItem}
            />
          </SidebarModal>
        )}

        <div className="form-stepper relative">
          <div className="flex justify-between absolute top-28 right-6">
            {eval(currentStep?.useCart) && (
              <button
                onClick={openCartSidebar}
                className="relative bg-primary duration-300 p-1 rounded"
              >
                <CartBagIcon width={20} height={20} fill={"#ffffff"} />
                <span className="absolute bg-red-600 text-xs text-white font-bold rounded-full -top-3 -right-3 w-5 h-5 inline-grid place-items-center">
                  {this.state.cart?.length ?? 0}
                </span>
              </button>
            )}
          </div>

          {
            <div className="mb-6">
              <Stepper
                steps={steps}
                activeStep={page}
                activeColor={theme?.colors.primary}
                // defaultBarColor="#B24190"
                completeColor={theme?.colors.primary}
                completeBarColor={theme?.colors.primary}
              />
            </div>
          }

          <div className="px-4">
            {currentStep?.type === "ListView1" ? (
              <ListCartSource
                formData={this.state.formData}
                setFormData={this.setFormData}
                nextPage={
                  this.state.page + 1 === this.state.formStepper?.length
                    ? null
                    : this.nextPage
                }
                target_id={this.props.target_id}
                // prevStep={previousStep}
                currStep={currentStep}
                previousPage={
                  this.state.page - 1 < 0 ? null : this.previousPage
                }
                actionButton={previousStep?.nextButtonAction}
                button_action={this.props.button_action}
                previewTransaction={this.setIsOpen}
                formInitialValues={this.state.formInitialValues}
                formDefaultValues={this.formDefaultValues}
                setNewInitialValues={this.setNewInitialValues}
                clearCart={this.clearCart}
                updateRecord={this.props.updateRecord}
                handleSubmit={this.props.handleSubmit}
              />
            ) : (
              <Form1
                {...currentStep}
                carts={this.props.carts}
                edit={this.props.edit}
                showPreview={this.props.showPreview}
                button_action={this.props.button_action}
                target_id={this.props.target_id}
                formData={this.state.formData}
                setFormData={this.setFormData}
                nextPage={
                  this.state.page + 1 === this.state.formStepper?.length
                    ? null
                    : this.nextPage
                }
                previousPage={
                  this.state.page - 1 < 0 ? null : this.previousPage
                }
                addItemToCart={this.addItemToCart}
                deleteItemFromCart={this.deleteItemFromCart}
                clearCart={this.clearCart}
                cartItems={this.state.cart}
                updatePreviewData={this.updatePreviewData}
                previewTransaction={this.setIsOpen}
                formInitialValues={this.state.formInitialValues}
                formDefaultValues={this.formDefaultValues}
                setNewInitialValues={this.setNewInitialValues}
                updateRecord={this.props.updateRecord}
                activeCart={this.state.activeCart}
                currStep={currentStep}
              />
            )}
            {/* : React.createElement(component[this.props.empty || "EmptySection"])} */}
          </div>
        </div>
      </>
    );
  }
}

export default WithLoader(FormStepper1);
