import React, { useContext } from "react";
import { Dialog } from "@headlessui/react";
import renderComponent from "../renderComponent";
import ModalLayout1 from "./ModalLayout1";
import { appendQueryToUri } from "../util/generalUtil";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import apiEndpoints from "../apiEndpoints";
import { DownloadIcon, PlusIcon, XIcon } from "@heroicons/react/solid";
import WithLoader from "./WithLoader";
import WalletLogo from "../assets/images/wemaBank.png";
import { CheckmarkIcon } from "react-hot-toast";
import { ThemeContext } from "styled-components";
import {
  GoogleMap,
  LoadScript,
  LoadScriptNext,
  Marker,
} from "@react-google-maps/api";

const MySwal = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      cancelButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
    },
    buttonsStyling: false,
  })
);

function MapControlModal({ isOpen, onClose, latitude, longitude }) {
  const theme = useContext(ThemeContext);

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={() => {}}
      open={isOpen}
    >
      <div className="min-h-screen h-full px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className=" flex justify-center items-center">
          <div className="inline-block w-full xl:max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-2xl">
            <div className="flex justify-end gap-2">
              <button
                onClick={onClose}
                className="bg-primary hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 rounded-md inline-flex items-center mb-4"
              >
                <XIcon fill="#ffffff" width={16} height={16} />
                <h3 className="uppercase ml-2 hidden lg:block">Close</h3>
              </button>
            </div>

            <div>
              <LoadScriptNext googleMapsApiKey="AIzaSyCJgg4sQpU4NKpPDkgtmSDtQxeTC5A9sXU">
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "400px" }}
                  zoom={14}
                  center={{
                    lat: parseFloat(latitude),
                    lng: parseFloat(longitude),
                  }}
                >
                  <Marker
                    position={{
                      lat: parseFloat(latitude),
                      lng: parseFloat(longitude),
                    }}
                  />
                </GoogleMap>
              </LoadScriptNext>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default WithLoader(MapControlModal);
