import {
  ChevronUpIcon,
  SearchIcon,
  AdjustmentsIcon,
} from "@heroicons/react/solid";
import AdvanceSelect from "./AdvanceSearchSelect";
import { useFilters } from "../contexts/FilterContext";

export function AdvanceSearchButton({ open, onClick }) {
  return (
    <button
      onClick={onClick}
      className="flex w-full justify-between rounded-lg bg-gray-100 px-4 py-[10px] mt-1 text-left text-sm font-medium text-dark hover:bg-gray-200 focus:outline-none"
    >
      <span className="mr-2">Filters</span>
      <AdjustmentsIcon
        className={`${open ? "rotate-180 transform" : ""} w-5 text-gray-500`}
      />
    </button>
  );
}

function transformFilters(filters) {
  return Object.entries(filters).map(([key, value]) => ({
    [key]: value.map((item) => item.value),
  }));
}

export function FilterField({ field, keyIdx, fieldNomalized, headerProps }) {
  return (
    <div className="relative rounded-md flex shadow py-0.5 pl-2.5 pr-2 border text-sm text-gray-600 transition-all shadow-sm items-center">
      <span className="font-bold">{fieldNomalized}</span>
      <AdvanceSelect targetId={headerProps.targetid} target={field} />
    </div>
  );
}

const areAllFiltersValid = (filters) => {
  return filters.some((filter) => {
    const key = Object.keys(filter)[0];
    return filter[key].length > 0;
  });
};

export function AdvanceSearchPanel({
  open,
  filterFields,
  headerProps,
  fetchData,
}) {
  const { filters } = useFilters();

  if (!open) return null;

  const refineSearch = () => {
    var result = transformFilters(filters);
    if (areAllFiltersValid(result)) {
      fetchData(null, null, JSON.stringify(result));
    }
  };

  console.log("let's see filter fields", filterFields);
  return (
    <>
      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />
      <div className="flex items-center justify-end">
        <div className="flex gap-2">
          {filterFields?.map((field, idx) => (
            <FilterField
              key={idx}
              fieldNomalized={field
                .replace(/^filterby_/, "")
                .replace(/_/g, " ")}
              headerProps={headerProps}
              field={field}
              keyIdx={idx}
            />
          ))}
        </div>
        <div className="ml-2">
          <button
            className="text-sm bg-primary p-2 text-white shadow rounded-md px-4 flex gap-2 items-center"
            onClick={refineSearch}
          >
            {/* Text for Medium and Larger Screens */}
            <span className="hidden md:inline">Refine Search</span>
            {/* Icon Always Visible */}
            <SearchIcon className="md:w-4 md:h-4 w-4 h-7 text-white" />
          </button>
        </div>
      </div>
      <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
    </>
  );
}
