import React from "react";
import { Dialog, Transition } from "@headlessui/react";


const AsyncFunction = (async function () {}).constructor;

function ConfirmDialog(props) {
  let { confirmDialog, setConfirmDialog } = props;
  const handleClick = confirmDialog.onConfirm
  const isAsyncHandleClick = handleClick instanceof AsyncFunction


  console.log("This is called from ConfirmDialog screen")
  return (
    <Dialog
      open={confirmDialog.isOpen}
      as="div"
      onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      <div className="min-h-screen h-full px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <span className="inline-block h-full align-middle" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block align-middle bg-white rouded-lg px-4 pt-5 pb-4 text-left overflow-hidden
              shadow-xl transform transition-all sm:my-0 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 rounded-lg">
          <Dialog.Title>
            <h6 className="hover:cursot-default border-b text-lg font-semibold">Confirm the action</h6>
          </Dialog.Title>
          <Dialog.Description className="items-center p-2">
            <p>{confirmDialog.title}</p>
            <p>{confirmDialog.subTitle}</p>
          </Dialog.Description>
            <div className="space-x-3 flex justify-center">

          <button
            className="px-4 py-2 text-sm font-medium rounded-md text-black border-transparent bg-accent cursor-pointer border-b hover:shadow-md"
            onClick={() =>
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }
          >
            No
          </button>
          {isAsyncHandleClick 
            ? <button
                className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium
                          text-white border-b bg-primary cursor-pointer hover:shadow-md"
                onClick={e => {
                  setConfirmDialog({ ...confirmDialog, isOpen: false })
                  handleClick()
                }}>
                Yes
              </button>
            : <button
              className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium
                        text-white border-b bg-primary cursor-pointer hover:shadow-md"
              onClick={async e => {
                setConfirmDialog({ ...confirmDialog, isOpen: false })
                await handleClick()
              }}>
              Yes
            </button>
            }
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmDialog;
