import React, { useContext, useEffect, useState } from "react";
import AppContext from "../contexts/AppContext";
import WithLoader from "./WithLoader";
import appFetch from "../appFetch";
import { appendQueryToUri } from "../util/generalUtil";
import apiEndpoints from "../apiEndpoints";
import Select from "react-select";
import renderComponent from "../renderComponent";
import moment from "moment";
import { CalendarIcon } from "@heroicons/react/solid";
import ReactDatePicker from "react-datepicker";
import Spinner from "../loaders/Spinner";
import { createBrowserHistory } from "history";

window._moment = moment;

const ReportOption = ({ innerProps, data, children, label }) => {
  return (
    <div className="custom-select-option" {...innerProps}>
      {children}
    </div>
  );
};

const ReportOptionValue = ({ children, data }) => {
  return <div className="custom-select-option-value">{children}</div>;
};

function MainArea1({ setLoading, subcomponents, type }) {
  const { context, setContext } = useContext(AppContext);

  const history = createBrowserHistory();
  const {
    location: { pathname },
  } = history;

  let _pathname = pathname.split("/").slice(-1)[0]?.toLowerCase() || "home";
  _pathname = decodeURI(_pathname.replace(/\//, "")).replace(/\-/g, " ");
  let patt = RegExp(_pathname, "i");
  console.log("patt mainArea", patt);

  console.log("the context in mainArea", context.pageConfig);

  let menuList =
    context?.pageConfig?.subcomponents[0]?.subcomponents[0]?.subcomponents[1]
      ?.options;

  let activeMenu = menuList?.find((menu) => patt.test(menu?.name));

  // console.log("the menulist and activeMenu", menuList, activeMenu);

  let date_filter = activeMenu?.date_filter;
  let access_filter = activeMenu?.access_filter;
  let scroll = activeMenu?.scroll;

  const [newsubscomponents, setNewsubcomponents] = useState(subcomponents);
  const [activeReport, setActiveReport] = useState(null);
  const [activePeriod, setActivePeriod] = useState(null);
  const [activePeriodType, setActivePeriodType] = useState(null);
  const [dataGrid, setDataGrid] = useState(null);

  const [activeAccessFilter, setActiveAccessFilter] = useState(null);
  const [accessFilterData, setAccessFilterData] = useState(null);

  const [activeDateFilter, setActiveDateFilter] = useState(null);
  const [dateFilterData, setDateFilterData] = useState(null);

  const [dateRange, setDateRange] = useState(null);
  const [dateRange2, setDateRange2] = useState(null);
  const [filterDateRange, setFilterDateRange] = useState(null);
  const [loadDate, setLoadDate] = useState(false);

  const currentYear = new Date().getFullYear();

  const minDate = new Date(currentYear - 1, 0, 1);
  const maxDate = new Date(currentYear, 11, 31);

  //call other_view api. target:name of the proprty, target_id:json_property - menu I am getting the parameter in the first place,

  console.log(
    "the add_filter value and subcomponents",
    newsubscomponents,
    date_filter
  );
  const [filterOption, setFilterOption] = useState(null);
  const [accessOption, setAccessOption] = useState(null);

  console.log("the date_filter and options on mainArea", date_filter);

  useEffect(() => {
    // Update the local `pageConfigg` state whenever `context.pageConfig` changes

    if (context?.menuId) {
      setNewsubcomponents(subcomponents); // Update subcomponents based on context
      setActiveReport(activeReport);
      setActiveDateFilter(activeReport);
      setActivePeriodType(activePeriodType);
      setActivePeriod(activePeriod);

      console.log("Updated context detected, recalculating state:", {
        activeReport,
        dataGrid,
      });
    }
  }, [context]);

  useEffect(() => {
    if (access_filter) {
      fetchAcessFilterDropdownData();
    }

    if (date_filter) {
      fetchDateFilterDropdownData();
    }
  }, [access_filter]); // Runs whenever access_filter changes

  const fetchAcessFilterDropdownData = () => {
    setLoading(true);
    let postData = {
      target: access_filter,
      targetId: activeMenu?.targetid,
    };
    console.log("the access_filter postdata", postData);

    appFetch
      .get(appendQueryToUri(apiEndpoints.dropdownView, postData))
      .then((res) => {
        console.log("the access_filter options are: ", res);
        setAccessFilterData(res);
        setActiveAccessFilter(res?.[0]);
        setAccessOption((res ?? [])[0]?.value);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const fetchDateFilterDropdownData = () => {
    setLoading(true);
    let postData = {
      target: date_filter,
      targetId: activeMenu?.targetid,
    };
    console.log("the date_filter postdata", postData);

    appFetch
      .get(appendQueryToUri(apiEndpoints.dropdownView, postData))
      .then((res) => {
        console.log("the date_filter options are: ", res);
        setDateFilterData(res);
        setActiveDateFilter(res?.[0]);
        setFilterOption((res ?? [])?.[0]?.value);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const filterByDateRange = (dateFilter) => {
    setLoading(true);

    let postData = {
      layoutId: "",
      schemaId: "",
      target: "MENU",
      targetId: activeMenu?.targetid,
      dateFilter: dateFilter ? JSON.stringify(dateFilter) : null,
      // periodFilter: date_filter ? date_filter : null,
    };

    setFilterOption(JSON.stringify(dateFilter));
    console.log("the filterbyDate postDATA", postData);

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        let newsubcom = res?.subcomponents[0]?.subcomponents;
        console.log("New custom menu layout", res, postData);
        console.log(
          "the add_filter custom value and subcomponents",
          res,
          postData,
          newsubcom
        );
        setNewsubcomponents(newsubcom);
      })
      .catch((e) => console.log(e.message))
      .finally(() => {
        setLoading(false);
        setLoadDate(false);
      });
  };

  const fetchReportNewHomeLayout = (reportType, secondaryReport) => {
    setLoading(true);

    console.log("the current report type is", reportType);

    if (reportType.name == "Custom") {
      setLoading(false);
      return;
    }

    let postData = {
      layoutId: "",
      schemaId: "",
      target: "MENU",
      targetId: activeMenu?.targetid,
      dateFilter: reportType.name,
      accessFilter: secondaryReport ? secondaryReport.value : null,
      // periodFilter: date_filter ? date_filter : null,
    };

    setFilterOption(reportType?.value);
    setAccessOption(secondaryReport?.value);

    console.log("new HomeScreen postData", postData);

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        let newsubcom = res?.subcomponents[0]?.subcomponents;
        console.log("New menu layout", res, postData);
        console.log(
          "the add_filter value and subcomponents new page",
          res,
          postData,
          newsubcom
        );
        setNewsubcomponents(newsubcom);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const fetchAccessNewHomeLayout = (reportType, secondaryReport) => {
    setLoading(true);

    console.log("the current report type is", reportType);

    if (reportType.name == "Custom") {
      setLoading(false);
      return;
    }

    let postData = {
      layoutId: "",
      schemaId: "",
      target: "MENU",
      targetId: activeMenu?.targetid,
      dateFilter: secondaryReport?.name,
      accessFilter: reportType ? reportType.value : null,
      // periodFilter: date_filter ? date_filter : null,
    };

    setFilterOption(secondaryReport?.value);
    setAccessOption(reportType?.value);

    console.log("new HomeScreen postData", postData);

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        let newsubcom = res?.subcomponents[0]?.subcomponents;
        console.log("New menu layout", res, postData);
        console.log(
          "the add_filter value and subcomponents new page",
          res,
          postData,
          newsubcom
        );
        setNewsubcomponents(newsubcom);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const ondateFilterChange = (selectedOption) => {
    setActiveDateFilter(selectedOption);
    fetchReportNewHomeLayout(selectedOption, activeAccessFilter);
  };

  const onaccessFilterChange = (selectedOption) => {
    console.log("the selected Option is", selectedOption);
    setActiveAccessFilter(selectedOption);
    fetchAccessNewHomeLayout(selectedOption, activeDateFilter);
  };

  let filteredDatefilter = subcomponents?.filter(
    (component) =>
      component.type === "DateNotificationList1" ||
      component.type === "DateNotificationList2"
  );

  console.log("the filteredDateFilter", filteredDatefilter);

  let DateReportOptions =
    (filteredDatefilter ?? [])[0]?.options?.map(
      ({ period, grid, ...rest }) => ({
        ...rest,
        label: rest.name,
      })
    ) || [];

  let initialActiveReport = DateReportOptions[0] ?? null;
  let datefilterOptions = (filteredDatefilter ?? [])[0]?.options;

  console.log("what is the filter option", filterOption);

  return (
    <>
      {
        <div
          className={`lg:px-8 px-4 pb-8 pt-14 lg:pt-8 w-rb overflow-auto ${
            scroll === "yes"
              ? " scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
              : ""
          }`}
        >
          <div className="flex-col">
            <div
              className={`border-bottom flex ${
                access_filter ? "justify-between" : "justify-end"
              }  items-center rounded-md py-2`}
            >
              {access_filter && (
                <div className="flex gap-2 sm:w-auto">
                  <Select
                    className="w-full sm:w-auto"
                    classNamePrefix="custom-select-option"
                    components={{
                      Option: ReportOption,
                      SingleValue: ReportOptionValue,
                    }}
                    value={activeAccessFilter}
                    isClearable={false}
                    onChange={onaccessFilterChange}
                    options={accessFilterData}
                  />
                </div>
              )}

              {date_filter && (
                <div className="flex gap-2 sm:w-auto">
                  <Select
                    className="w-full sm:w-auto"
                    classNamePrefix="custom-select-option"
                    components={{
                      Option: ReportOption,
                      SingleValue: ReportOptionValue,
                    }}
                    value={activeDateFilter}
                    isClearable={false}
                    onChange={ondateFilterChange}
                    options={dateFilterData}
                  />
                </div>
              )}
            </div>
          </div>
          {activeDateFilter?.value === "Custom" && (
            <div className="flex md:flex md:space-x-5 items-center justify-end">
              <div className="flex items-center justify-between border-2 border-gray-300 bg-white h-10 pl-3 rounded-lg text-sm focus:outline-none">
                <ReactDatePicker
                  selectsRange={false}
                  minDate={minDate}
                  maxDate={maxDate}
                  wrapperClassName="bg-transparent focus:outline-none"
                  dateFormat={"MMM, d"}
                  selected={dateRange}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  placeholderText="Start date"
                  clearButtonClassName="text-primary hover:bg-primary rounded-md"
                  isClearable={true}
                  clear="close"
                />
                <div className="flex-1 pr-2">
                  <CalendarIcon width={20} height={20} fill="#9e9e9e" />
                </div>
              </div>

              <h2>-</h2>

              <div className="flex items-center justify-between border-2 border-gray-300 bg-white h-10 pl-3 rounded-lg text-sm focus:outline-none mb-5 md:mb-0">
                <ReactDatePicker
                  selectsRange={false}
                  minDate={dateRange ?? minDate}
                  maxDate={maxDate}
                  wrapperClassName="bg-transparent focus:outline-none"
                  dateFormat={"MMM, d"}
                  selected={dateRange2}
                  onChange={(update) => {
                    setDateRange2(update);
                  }}
                  placeholderText="End date"
                  clearButtonClassName="text-primary hover:bg-primary rounded-md"
                  isClearable={true}
                  clear="close"
                />
                <div className="flex-1 pr-2">
                  <CalendarIcon width={20} height={20} fill="#9e9e9e" />
                </div>
              </div>

              <button
                disabled={!dateRange && !dateRange2 ? true : false}
                onClick={() => {
                  if (
                    moment(dateRange)._isValid &&
                    moment(dateRange2)._isValid
                  ) {
                    const dateFilter = {
                      startDate: moment(dateRange).format("yyyy-MM-D"),
                      endDate: moment(dateRange2).format("yyyy-MM-D"),
                    };
                    setLoadDate(true);
                    setFilterDateRange(dateFilter);
                    filterByDateRange(dateFilter);
                  }

                  if (
                    !moment(dateRange)._isValid &&
                    !moment(dateRange2)._isValid
                  ) {
                    setFilterDateRange(null);
                  }
                }}
                className={`${
                  dateRange && dateRange2
                    ? "bg-primary "
                    : "bg-gray-300 text-black"
                }hover:bg-primary text-white tracking-normal text-center font-semibold text-xs py-[10px] px-4 rounded-lg inline-flex items-center`}
                // style={{ borderRadius: "1rem" }}
              >
                <h3 className="text-sm">
                  {loadDate ? (
                    <span className="">
                      {" "}
                      <Spinner fill="#e2e3e4" width={18} height={18} />
                    </span>
                  ) : (
                    <span className="">Search</span>
                  )}
                </h3>
              </button>
            </div>
          )}
          <div className="">
            <div className="mt-6">
              <div>
                {newsubscomponents?.map((config, idx) =>
                  renderComponent(config, {
                    filterOption,
                    initialActiveReport,
                    filteredDatefilter,
                    accessOption,
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default WithLoader(MainArea1);
