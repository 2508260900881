import classNames from "classnames";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import components from ".";
import apiEndpoints from "../apiEndpoints";
import appFetch from "../appFetch";
import formstepper from "../formStepperData.json";
import { appendQueryToUri } from "../util/generalUtil";
import ImportDialog from "./ImportDialog";
import WithLoader from "./WithLoader";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ThemeContext } from "styled-components";
import { Dialog } from "@headlessui/react";
import { PlusCircleIcon, PlusSmIcon, XIcon } from "@heroicons/react/solid";
import PlusIcon from "../icons/PlusIcon";
import SidebarModal from "./SidebarModal";
import renderComponent from "../renderComponent";

window._moment = moment;

const MySwal = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      cancelButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
    },
    buttonsStyling: false,
  })
);

function GridAndFormWrapper2({
  children,
  name,
  layoutid,
  search,
  setLoading,
  setInProp,
  updateFormState,
  inProp,
  loading,
}) {
  // let {children,formHidden,setFormHidden} = props
  // let {children} = props

  // useEffect(() => {
  //     setFormHidden(true);
  // }, [name])

  // console.log("props===",props)

  // const form = children[0]?.props?.children?.props;
  // const savedRecords = children[1]?.props?.children?.props;

  const [formHidden, setFormHidden] = useState(true);
  const form = children[0]?.props?.children?.props;
  const [savedRecords, setSavedRecords] = useState(
    children[1]?.props?.children?.props
  );
  const [isOpen, setIsOpen] = useState(false);
  const theme = useContext(ThemeContext);

  const [edit, setEdit] = useState(null);
  const [formClone, setFormClone] = useState(null);
  const [importDialogOpen, setImportDialogOpen] = useState(false);

  console.log("the form", form);

  const updateRecord = (records) => {
    console.log("new records", records);
    setSavedRecords(records);
    setFormHidden(true);
  };

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const { excel_template, name: templateName, target_id } = form;
  const { target_type } = savedRecords ?? {};
  const templateHeader = excel_template?.reduce((a, b) => {
    let newObj = { ...a };
    newObj[b.name] = "";
    return newObj;
  }, {});

  console.log("grid target type", target_type);

  const editRecord = (item) => {
    let valuesToEdit = item.edit[0];

    // let newform  = {...form, button_action :"UPDATE"};
    let newform = {
      ...JSON.parse(JSON.stringify(form)),
      button_action: "UPDATE",
    };
    newform?.schema[0]?.options.forEach((o) => {
      switch (o.type) {
        case "multiselect":
          o["value"] =
            typeof valuesToEdit[o.edit] == "string"
              ? JSON.parse(valuesToEdit[o.edit] ?? "[]")
              : valuesToEdit[o.edit];
          break;
        case "upload":
          o["value"] = valuesToEdit[o.edit] ?? [];
          break;
        default:
          o["value"] = valuesToEdit[o.edit];
      }
    });

    setFormClone(newform);
    setEdit(valuesToEdit);
    setFormHidden(false);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const activeForm = formClone ?? form;

  let { schema, ...others } = activeForm;
  let formOptions = {
    ...others,
    edit: true,
    options: schema[0]?.options,
  };

  let formRecords = savedRecords?.options[0]?.grid;

  const defaultComponent = !formHidden ? activeForm : savedRecords;
  const defaultProps = !formHidden
    ? {
        ...formOptions,
        edit,
        setFormHidden,
        updateRecord,
        setInProp,
        closeModal,
        updateFormState,
        setLoading,
      }
    : {
        ...savedRecords,
        updateRecord,
        editRecord,
        search,
        layoutid,
        setInProp,
        closeModal,
        updateFormState,
        setLoading,
      };

  console.log(
    "the defaultComponent and defaultProps",
    defaultComponent,
    defaultProps,
    formRecords
  );

  const setFormNew = () => {
    if (formHidden == true) {
      setFormHidden(false);
    }
    setEdit(null);
    setFormClone(null);
  };

  console.log("new default props and isOpen", defaultProps, isOpen);

  return (
    <>
      {formHidden ? (
        <Dialog
          as="div"
          className="fixed inset-0 z-2 overflow-y-auto"
          onClose={() => {}}
          open={isOpen}
        >
          <div className="min-h-screen h-full px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            <div className=" flex justify-center items-center">
              <div className="inline-block w-full xl:max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-2xl">
                <div className="flex justify-end gap-2 items-center">
                  <button
                    onClick={() => {
                      updateFormState();
                      setInProp(false);
                      closeModal();
                    }}
                    className="bg-primary hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 rounded-md inline-flex items-center"
                  >
                    <XIcon fill="#ffffff" width={16} height={16} />
                    <h3 className="uppercase ml-2 hidden lg:block">Close</h3>
                  </button>
                </div>

                <div>
                  <div className="font-semibold md:text-xl text-base">
                    Set Active Wallet
                  </div>

                  {defaultComponent?.type &&
                    React.createElement(components[defaultComponent.type], {
                      ...defaultProps,
                    })}

                  <div
                    onClick={() => {
                      setFormNew();
                    }}
                    className="flex flex-row items-center space-x-7 p-5 cursor-pointer hover:bg-gray-50 transform transition-transform"
                  >
                    {/* image */}
                    <div
                      style={{ backgroundColor: theme?.colors.accent }}
                      className="py-4 px-4 rounded-xl"
                    >
                      {/* <img src={WalletLogo} className="h-7 object-contain" /> */}
                      <PlusSmIcon height={40} color={theme?.colors.primary} />
                    </div>

                    {/* wallet name and information */}
                    <div className="flex-1">
                      <div className="capitalize font-semibold md:text-lg text-md">
                        Add Wallet
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      ) : (
        <SidebarModal
          inProp={inProp}
          closeSidebar={() => {
            updateFormState();
            setInProp(false);
          }}
          loading={loading}
        >
          {defaultComponent?.type &&
            React.createElement(components[defaultComponent.type], {
              ...defaultProps,
            })}
        </SidebarModal>
      )}
    </>
  );
}

export default GridAndFormWrapper2;
