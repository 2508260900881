import React, { Fragment, useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import DownloadIcon from "../icons/DownloadIcon";
import formatter, { formatNumber } from "../util/formatter";
import uuid from "react-uuid";
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import EmptyTransactionMiniIcon from "../icons/EmptyTransactionMiniIcon";
import styled from "styled-components";
import icons from "../icons";
import { useTheme } from "styled-components";
import LeftBarContext from "../contexts/LeftBarContext";
import AppContext from "../contexts/AppContext";
import {
  ChevronRightIcon,
  ArrowRightIcon,
  PlusCircleIcon,
  ChevronLeftIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import WalletLogo from "../assets/images/wemaBank.png";
import ZenithLogo from "../assets/images/zenith.png";
import PlusRound from "../icons/PlusRound";
import { Menu, Transition, Tab } from "@headlessui/react";
import WalletControlModal, {
  WalletControlForm,
  WalletControlForm2,
} from "./WalletControlModal";
import AddWalletModal from "./AddWalletModal";
import WithLoader from "./WithLoader";
import appFetch from "../appFetch";
import { appendQueryToUri } from "../util/generalUtil";
import apiEndpoints from "../apiEndpoints";
import ConfirmDialog from "./ConfirmDialog";
import loader from "../assets/lotties/loader";
import Lottie from "react-lottie";

// import EmptyRecord from './EmptyRecord';
const Dialog = styled.div`
  width: ${({ type }) =>
    ["Notifications", "Profile"].includes(type) ? "368px" : "auto"};
`;

const LeftBarToggler = styled.div`
  width: 32px;
  height: 32px;
  /* background: #fcfcfc; */
`;

const walletTypeMap = {
  "000003": "fcmb",
  "000015": "zenith",
  "000017": "wema",
};

const iconMap = {
  wema: WalletLogo,
  zenith: ZenithLogo,
};
// let options = [
//   {
//     name: "Fund Wallet",
//     useCart: false,
//     icon: "fundWallet",
//     showPreview: false,
//     target: "component",
//     button_action: "SAVE",
//     targetid: 91750,
//   },
//   {
//     name: "Withdraw",
//     useCart: false,
//     icon: "withdraw",
//     showPreview: false,
//     target: "component",
//     button_action: "SAVE",
//   },
//   {
//     name: "Pay Baba",
//     useCart: false,
//     icon: "pay",
//     showPreview: false,
//     target: "component",
//     button_action: "SAVE",
//   },
//   {
//     name: "Wallet History",
//     useCart: false,
//     icon: "walletHistory",
//     showPreview: false,
//     target: "component",
//     button_action: "SAVE",
//   },
//   {
//     name: "Reset Pin",
//     useCart: false,
//     icon: "reset",
//     showPreview: false,
//     target: "component",
//     button_action: "SAVE",
//   },
// ];

const Dropdown = ({
  name,
  type,
  loadForm,
  loadForm2,
  confirmDialog,
  setConfirmDialog,
  options,
  walletData,
  setLoading,
}) => {
  let walletOptions = options[0]?.options;
  let icon = options[0]?.icon;
  let dropdownOptions = walletOptions?.filter(
    (option) => option.icon !== "add"
  );
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  let addNewWallet = walletOptions?.filter((option) => option.icon === "add");
  let Icon = iconMap[icon?.toLowerCase()];

  console.log("the wallet options", dropdownOptions, addNewWallet);
  return (
    <Menu as="div">
      {/* Main Dropdown Button */}
      <Menu.Button
        onClick={() => console.log("Dropdown Clicked")} // Main dropdown click action
        className="flex items-center justify-center relative flex-row text-gray-700 hover:bg-gray-50 w-full"
      >
        {/* <div className="flex flex-row items-center space-x-4 px-4 py-2 shadow-md bg-white border rounded-full"> */}
        <div className="ml-2 lg:ml-0 inline-flex items-center space-x-3 justify-between w-full rounded-md  py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
          {/* Logo */}
          <div className="flex items-center gap-2">
            <div className="px-1">
              <img
                src={Icon}
                // className="h-5 object-contain"
                className="h-8 w-12 object-contain"
              />
            </div>

            {/* Wallet Name & Balance */}
            <div className="text-left">
              <div className="text-main-black uppercase font-black tracking-wide text-base">
                {icon}
              </div>
              <div
                style={{ color: "#838383" }}
                className="capitalize font-medium tracking-wide text-xs"
              >
                {walletData?.payload >= 0 ? (
                  <span>
                    <span>&#8358;</span>
                    {formatNumber(walletData?.payload)}
                  </span>
                ) : (
                  <Lottie options={defaultOptions} height={14} width={40} />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Separate Button for `+` Icon */}
        <div
          //   className="border rounded-full px-2 py-[8px] -ml-3 shadow-md bg-white cursor-pointer"
          className="cursor-pointer shadow-md px-2 py-2 rounded-full -ml-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={(e) => {
            e.stopPropagation(); // Prevent dropdown from opening
            console.log("the addNewWallet", addNewWallet);
            setLoading(true);
            loadForm2(addNewWallet[0]);
            // openModal(); // Open modal instead
          }}
        >
          <PlusIcon color="#000000" width={20} />
        </div>
      </Menu.Button>

      {/* Dropdown Items */}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items
          as={Dialog}
          type={type}
          className="origin-top-right absolute transform left-1/2 -translate-x-1/2 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <WalletControlModal
            options={dropdownOptions}
            loadForm={loadForm}
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

//456 file:

function WalletMenuList1({ setLoading, name, options }) {
  let walletBalance = options[0]?.wallet_balance;
  const walletObject = JSON.parse(walletBalance);

  const updatedWalletObject = {
    ...walletObject,
    walletType: walletTypeMap[walletObject?.walletType] || walletObject?.walletType, // Fallback to original code if not found
  };

  const [loader, setLoader] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const [form, setForm] = useState(null);
  const [form2, setForm2] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState([
    { name: "wema", icon: "wema", account_number: "0246854849" },
  ]);
  const [activeWallet, setActiveWallet] = useState(modalData[0]);
  const [walletData, setWalletData] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    setLoader(true);
    // setInProp(true);

    let postData = {
      phoneNumber: updatedWalletObject?.phoneNumber,
      walletType: updatedWalletObject?.walletType,
      walletNumber: updatedWalletObject?.walletNumber,
    };

    // setForm(formData)
    // setLoading(false)

    appFetch
      .post("https://hts-wallet.azurewebsites.net/wallet/balance", {
        body: JSON.stringify(postData),
      })
      .then((res) => {
        console.log("value of result from get wallet balance", res);
        setWalletData(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoader(false));
  }, []);

  const loadForm = (item) => {
    console.log("item", item);
    setForm(item);
  };

  const loadForm2 = (item) => {
    console.log("item => add wallet", item);
    setForm2(item);
  };

  const { context } = useContext(AppContext);
  const { menuCollapse, setMenuCollapse, setMobileMenuOpen } =
    useContext(LeftBarContext);

  let index = "001200";

  console.log("the active wallet is", activeWallet);
  console.log("the add wallet form", form2);

  return (
    <div>
      {/* {!menuCollapse && <hr className="-mx-8 -mt-2 mb-2" />} */}
      {form && (
        <WalletControlForm
          item={form}
          updateFormState={() => loadForm(null)}
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          openModal={() => setIsOpen(true)}
        />
      )}

      {form2 && (
        <WalletControlForm2
          item={form2}
          updateFormState={() => loadForm2(null)}
          setLoading={setLoading}
        />
      )}

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      {isOpen && (
        <AddWalletModal
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          openModal={() => setIsOpen(true)}
          item={modalData}
          setLoading={setLoading}
          activeWallet={activeWallet}
          setActiveWallet={setActiveWallet}
          //   dateFilter={filterOption}
        />
      )}

      {/* {menuCollapse && (
        <LeftBarToggler
          className="hidden cursor-pointer md:inline-grid place-items-center"
          onClick={() => setMenuCollapse(!menuCollapse)}
        >
          {React.createElement(icons["FileIcon"], {
            stroke: "gray",
            width: "22px",
            height: "22px",
          })}
        </LeftBarToggler>
      )} */}

      {!menuCollapse && (
        <>
          <div className="grid-flow-col gap-4 relative z-20 pt-0 pb-3">
            <Dropdown
              isOpen={isOpen}
              closeModal={setIsOpen}
              openModal={() => setIsOpen(true)}
              key={index}
              loadForm={loadForm}
              loadForm2={loadForm2}
              name={name}
              type={name}
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
              options={options}
              walletData={walletData}
              setLoading={setLoading}
              walletNumber= {walletObject[0]}
              // options={options}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default WithLoader(WalletMenuList1);
