import * as React from "react";

const WalletPaymentIcon = (props) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="6"
      width="18"
      height="13"
      rx="2"
      fill="none"
      stroke={props?.stroke}
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 10H20.5"
      stroke={props?.stroke}
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 15H9"
      stroke={props?.stroke}
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default WalletPaymentIcon;
