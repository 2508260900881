import { React, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import SidebarModal from "./SidebarModal";
import MapControlModal from "./MapControlModal";

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-96 bg-gray-100 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">No maps available</h2>
    </div>
  );
};

const StaticMap = ({ latitude, longitude, onClick }) => {
  const apiKey = "AIzaSyCJgg4sQpU4NKpPDkgtmSDtQxeTC5A9sXU";
  const staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=20&size=200x200&markers=color:red%7C${latitude},${longitude}&key=${apiKey}`;

  console.log("the static map long and lat", latitude, longitude);
  return (
    <img
      src={staticMapUrl}
      alt="Location Snapshot"
      // className="cursor-pointer rounded"
      className="h-12 w-12 object-contain cursor-pointer rounded "
      onClick={onClick}
    />
  );
};

const MyStyle = ({ selected }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);

  const { header, history } = JSON.parse(selected.map_drill);

  console.log("selected map_drill", selected, header, history);

  return (
    <div>
      <h2 className="uppercase font-semibold text-md pb-5">{header.agent}</h2>
      <hr className="pb-5" />
      {Object.entries(header).map(([key, value]) => (
        <div className="flex justify-between pb-4" key={key}>
          <h2 className="capitalize font-semibold text-md">{key}</h2>
          <h2 className="capitalize font-semibold text-md">{value}</h2>
        </div>
      ))}
      <hr className="pb-6" />
      <div className="text-teal-600 text-xl pb-10">Last Activity</div>
      {history.map((item, idx) => (
        <div key={idx} className="flex items-center justify-between pb-4">
          {item.picture && (
            <img src={item.picture} className="h-8 w-12 object-contain" />
          )}
          <p className="text-sm">{item.customer}</p>
          <div className="text-sm text-gray-500">{item.timestamp}</div>
          <div className="bg-white p-1 shadow-md hover:scale-105 hover:bg-gray-50 hover:skew-x-3 transition-transform duration-200 ease-in-out">
            <StaticMap
              latitude={item.latitude}
              longitude={item.longitude}
              onClick={() => {
                setSelectedCoordinates({
                  latitude: item.latitude,
                  longitude: item.longitude,
                });
                setModalOpen(true);
              }}
            />
          </div>
        </div>
      ))}
      {selectedCoordinates && (
        <MapControlModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          latitude={selectedCoordinates.latitude}
          longitude={selectedCoordinates.longitude}
        />
      )}
    </div>
  );
};

const MapControl1 = ({ setLoading, name, options, selectedChartControl }) => {
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  options = selectedChartControl?.options[0] ?? options[0];

  const [selected, setSelected] = useState(null);
  const [inProp, setInProp] = useState(false);

  const onSelect = (item) => {
    setSelected(item);
    setInProp(true);
  };

  let newLocations = options?.data;

  const locations = [
    { name: "Location 1", lat: 12.9802, lng: 77.5907 },
    { name: "Location 2", lat: 12.9787, lng: 77.5917 },
    { name: "Location 3", lat: 12.9774, lng: 77.5901 },
    { name: "Location 4", lat: 12.9761, lng: 77.5872 },
    { name: "Location 5", lat: 12.9771, lng: 77.5857 },
  ];

  const path = locations.map(({ lat, lng }) => ({ lat, lng }));

  const MapOptions = {
    strokeColor: "#0088FF",
    strokeWeight: 6,
    strokeOpacity: 0.6,
  };

  // const MyStyle = (selected) => {
  //   selected = selected?.selected;

  //   console.log("selected: ", selected);
  //   let { id, name, latitude, longitude, map_drill } = selected;

  //   let mapDrill = JSON.parse(map_drill);

  //   console.log("the mapdrill is: ", mapDrill);
  //   let { header, history } = mapDrill;

  //   const drillKeys = Object.keys(header);
  //   // .filter(key => !invalidHeads.includes(key))

  //   return (
  //     <div className="font-sans">
  //       <section>
  //         <h2 className="uppercase font-semibold text-md pb-5">
  //           {header?.agent}
  //         </h2>
  //         <hr className="pb-5" />
  //         {
  //           <div>
  //             {Object.entries(header).map(([key, value]) => (
  //               <div className="justify-between flex pb-4" key={key}>
  //                 <h2 className="Capitalize font-semibold text-md">{key}</h2>
  //                 <h2 className="Capitalize font-semibold text-md">{value}</h2>
  //               </div>
  //             ))}
  //           </div>
  //         }
  //       </section>
  //       <hr className="pb-6" />
  //       <section>
  //         <div className="text-teal-600 text-xl pb-10">Last Activity</div>

  //         {history?.map((currItem, idx) => {
  //           return (
  //             <>
  //               <div className="flex items-center justify-between">
  //                 {currItem?.picture && (
  //                   <img
  //                     src={currItem?.picture}
  //                     className="h-8 w-12 object-contain"
  //                   />
  //                 )}

  //                 <div className="pb-4">
  //                   <p2 className="text-sm">{currItem?.customer}</p2>
  //                 </div>

  //                 <div className="pb-4">
  //                   <div className="text-sm text-gray-500">
  //                     {currItem?.timestamp}
  //                   </div>
  //                 </div>

  //                 <div className="pb-4">
  //                   <div className="text-sm">{currItem?.longitude}</div>
  //                   <div className="text-sm text-gray-500">
  //                     {currItem?.latitude}
  //                   </div>
  //                 </div>
  //               </div>
  //             </>
  //           );
  //         })}
  //       </section>
  //     </div>
  //   );
  // };

  const onLoad = (polyline) => {
    console.log("polyline: ", polyline);
  };

  const sideDetails = () => {
    return <div>This is the sidebar</div>;
  };

  console.log("the selected item is", selected);

  return (
    <LoadScript googleMapsApiKey="AIzaSyCJgg4sQpU4NKpPDkgtmSDtQxeTC5A9sXU">
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={16}
        center={{
          lat: parseFloat(newLocations?.[0]?.latitude ?? "0"), // Fallback to 0 if undefined
          lng: parseFloat(newLocations?.[0]?.longitude ?? "0"),
        }}
        // center={{ lat: locations[0].lat, lng: locations[0].lng }}
      >
        {Array.isArray(newLocations) && newLocations.length > 0 ? (
          newLocations.map((location, index) => {
            let currLocation = {
              lat: parseFloat(location.latitude ?? "0"), // Fallback to "0" to avoid NaN errors
              lng: parseFloat(location.longitude ?? "0"),
            };
            return (
              <div
                className="cursor-pointer"
                onClick={() => {
                  onSelect(location);
                }}
              >
                <Marker
                  key={index}
                  position={currLocation}
                  onClick={() => onSelect(location)}
                />

                <InfoWindow
                  position={{
                    lat: currLocation.lat + 0.0007,
                    lng: currLocation.lng,
                  }}
                  onClick={() => onSelect(location)}
                >
                  <div>
                    <h3>{location.name ?? "random"}</h3>
                    <p>
                      Coordinates: {location.latitude ?? "lat"},{" "}
                      {location.longitude ?? "long"}
                    </p>
                  </div>
                </InfoWindow>
              </div>
            );
          })
        ) : (
          <EmptyRecord />
        )}

        {selected && (
          <SidebarModal inProp={inProp} closeSidebar={() => setInProp(false)}>
            <MyStyle selected={selected} />
          </SidebarModal>
        )}

        <Polyline onLoad={onLoad} path={path} options={MapOptions} />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapControl1;
