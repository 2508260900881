import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import "../assets/styles/sidebar-modal.css";
import { XIcon } from "@heroicons/react/solid";
import styled from "styled-components";
import { ScreenLoader, ScreenOverlay } from "./ScreenLoader";
import Spinner from "../loaders/Spinner";

const CloseModal = styled.a`
  position: absolute;
  right: 10px;
  top: 0.75rem;
`;

const Loader = () => (
  <div className="w-full h-full grid place-items-center">
    <Spinner width={48} height={48} fill="#cccccc" />
  </div>
);

const checkoutTransition = {
  entered: { opacity: 1, transform: "translateX(0)" },
};

const duration = 100;

function SidebarModal({ children, inProp, closeSidebar, loading }) {
  const [enterProp, setEnterProp] = useState(null);

  useEffect(() => {
    setEnterProp(inProp);
  }, [inProp]);

  const close = () => {
    setEnterProp(false);
    setTimeout(function () {
      closeSidebar();
    }, 250);
  };

  return (
    <>
      {inProp && (
        <ScreenOverlay zIndex={998}>
          <Transition in={enterProp} timeout={duration}>
            {(state) => {
              console.log("transition state", state);
              return (
                <div
                  className="sidebar-modal-container"
                  style={{
                    ...checkoutTransition[state],
                  }}
                >
                  <div className="sidebar-modal-body p-6 pb-20 lg:pb-0 h-full overflow-auto">
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="sidebar-modal-head pb-4 pt-5 d-flex justify-content-end align-items-center mb-3 relative">
                          <CloseModal
                            href="#"
                            className="sidebar-modal-close absolute top-2"
                            onClick={close}
                          >
                            <em className="ti grid place-items-center rounded-md border border-gray-300 bg-white shadow-md">
                              <XIcon width={24} height={24} />
                            </em>
                          </CloseModal>
                        </div>
                        <div className="mt-3">{children}</div>
                      </>
                    )}
                  </div>
                </div>
              );
            }}
          </Transition>
        </ScreenOverlay>
      )}
    </>
  );
}

export default SidebarModal;
